import { Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { AdminModule } from '../../modules/admin/admin.module';
import { DashboardComponent } from '../../modules/admin/dashboard/dashboard.component';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})

export class ContentHeaderComponent implements OnInit, AfterViewInit {
  @Input() config: any;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    Feather.replace();
  }
}
