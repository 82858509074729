import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { finalize, map, switchMap } from "rxjs/operators";
import { AuthService } from './auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  public token : any = localStorage.getItem('token');

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService.showLoader()
    return from(this.authService.getAuthorizationData())
            .pipe(
                switchMap(token => {
                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // do nothing for now
                            }
                            return event;
                        }),
                        finalize(() => this.authService.hideLoader())
                    )

                })
            )
  }
}
