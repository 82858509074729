<!-- <nav aria-label="Page navigation example">
    <ul class="pagination">
        <li class="page-item"><a class="page-link" href="javascript:void(0)" (click)="previous()">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 17L15 12L20 7V17Z" fill="#52575C" />
            <path d="M14 17L9 12L14 7V17Z" fill="#52575C" />
          </svg></a></li> -->
<!-- <li class="page-item" *ngIf="totalPages > 9"><a class="page-link" href="javascript:void(0)" (click)="previousBlock()">...</a></li> -->
<!-- <li class="page-item" *ngFor="let page of pageBlock" [ngClass]="{'active': currentPage === page.page}" >
            
            <a class="page-link page-number"  href="javascript:void(0)" (click)="goToPage(page)">{{page.page}}</a>
        
        </li> -->
<!-- <li class="page-item" *ngIf="totalPages > 9"><a class="page-link" href="javascript:void(0)" (click)="nextBlock()">...</a></li> -->
<!-- <li class="page-item"><a class="page-link" href="javascript:void(0)" (click)="next()">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17L15 12L10 7V17Z" fill="#52575C" />
                <path d="M16 17L21 12L16 7V17Z" fill="#52575C" />
              </svg>
        </a></li>
    </ul>
</nav> -->


<div class="pagination">
    <button style="width: 83px;" (click)="previous()" [disabled]="currentPage === 1">
        <div class="butduv">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
        </div>
        &nbsp;&nbsp;Previous&nbsp;&nbsp;
    </button>
    <ng-container *ngFor="let page of pageBlock">
        <button (click)="goToPage(page)" [class.active]="currentPage === page.page">{{ page.page }}</button>
    </ng-container>
    <button style="width: 83px;" (click)="next()" [disabled]="currentPage === totalPages">
        Next&nbsp;&nbsp;
       <div class="butduv">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M4 11h12.17l-5.59-5.59L12 4l8 8-8 8-1.41-1.41L16.17 13H4v-2z" />
        </svg>
       </div>
    </button>
</div>