import { Injectable } from '@angular/core';
import { Observable , of } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseUrlNode = `${environment.apiUrlNode}`;

  constructor(private http: HttpClient) { }


  // public createStore(data: any) {
  //   return this.http.post(`${this.apiBaseUrl}`,
  //   {
  //     name:'createNewAccount',
  //     param:data
  //   });
  // }

  public createStore(data: any) {
    return this.http.post(`${this.apiBaseUrlNode}auth/register-admin`,data);
  }

  
  public inviteSignup(data): Observable<any> {
    return this.http.post(this.apiBaseUrlNode+`vendor/vendor-signup`,data);
  }


}
