import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OtpServiceService {

  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseUrlNode = `${environment.apiUrlNode}`

  constructor(private http: HttpClient) { }

  public otpVerify(data) {
    return this.http.post(`${this.apiBaseUrl}`,{
      name:'otpVerify',
      param:data
    });
  }

  public loginwithPassword(phone, pass) {
    return this.http.post(`${this.apiBaseUrl}`,{
      name:'otpVerify',
      param: {
        "password": pass,
        "otp": "",
        "user": phone,
        "method": "login",
        "storeId": "dashboard",
        "role": "SO"
      }

    });
  }

  public googleSignIn(email, name) {
    return this.http.get(`${this.apiBaseUrlNode}social-login/google-signup?email=${email}&name=${name}`)
  }

  public facebookSignIn(email, name) {
    return this.http.get(`${this.apiBaseUrlNode}social-login/facebook-signup?email=${email}&name=${name}`)
  }
}
