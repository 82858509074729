<!-- <ng-container >
   
</ng-container > -->
<a *ngIf="cell.type === 'modal'" href="#" data-toggle="modal" data-target="#orderDetailModal" (click)="orderModal(row)">{{row[cell.field]}}</a>
<!-- <ng-container *ngIf="cell.type === 'userModal'">
        <a href="#" data-toggle="modal" data-target="#userDialog" (click)="userDetilModal(row)">{{row[cell.field]}}</a>
</ng-container >

<ng-container *ngIf="cell.icon === 'rupee'">
        <i class="fas fa-rupee-sign"></i>{{row[cell.field]}}
</ng-container >
<ng-container *ngIf="cell.field == 'DeliveryBoy'">
        <select class="form-control form-control-sm" id="exampleFormControlSelect1" (change)="selectBoy($event.target.value,row)">
            <option>Select Delivery Boy</option>
            <option *ngFor="let select of DBoy" value={{select.id}}>
                {{select.name}}
            </option>
        </select>
</ng-container >  
<ng-container *ngIf="cell.statusbtn  === 'status_btn ' && row[cell.field] === 'active'">
    <button class="btn btn-sm   btn -info btn -sm" (click)="changeUserStatus(row)">inactive</button>
 </ng-container > 
 <ng-container *ngIf="cell.statusbtn  === 'status_btn ' && row[cell.field] === 'inactive'">
    <button class="btn btn-sm   btn -info btn -sm" (click)="changeUserStatus(row)">active</button>
 </ng-container >  
<ng-container *ngIf="cell.openOrderStatus == 'true'">
        <select class="form-control form-control-sm detailsOption" id="exampleFormControlSelect1" (change)="openModal($event.target.value,row)">
            <option *ngFor="let select of config.Status" value={{select.option}}>
                {{select.option}}
            </option>
        </select>
</ng-container >

<ng-container *ngIf="cell.title != 'AssignDelivery' && cell.type != 'modal' && cell.type != 'userModal' && cell.icon != 'rupee' && cell.statusbtn  != 'status_btn '">
    {{row[cell.field]}}
</ng-container >   
    -->