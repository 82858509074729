import { Attribute, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseURLNode = environment.apiUrlNode

  // private StoreID : any;
  storeId: any

  private options = {
    headers: new HttpHeaders()
  };
  private httpClient: HttpClient;
  adduserDelete: any;
  adduserEdit: any;
  getadduser: any;
  constructor(private http: HttpClient, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }


  public createCatalog(data, image, attribute, name): Observable<any> {

    return this.httpClient.post(`${environment.apiUrl}`, {
      name: 'createCatalog',
      param: {
        storeId: localStorage.getItem('storeunqId'),
        title: data.title,
        category: [`${name}`],
        price: data.price,
        description: data.description,
        quantity: 1,
        offer_price: data.offer_price,
        is_catalog: true,
        is_deal: data.deal_item || false,
        images: image,
        tax: data.tax || 0,
        attribute: attribute,
        brand: data.brand,
        prepaid: data.prepaid,
        cod: data.cod
      }
    });
  }

  public createCatalogVendor(data, image, attribute, name): Observable<any> {

    let datas = {
      storeId: localStorage.getItem('admin_storeId'),
      vendorId: localStorage.getItem('vendor_storeId'),
      title: data.title,
      category: [`${name}`],
      price: data.price,
      description: data.description,
      quantity: 1,
      offer_price: data.offer_price,
      is_catalog: true,
      is_deal: data.deal_item || false,
      images: image,
      tax: data.tax || 0,
      attribute: attribute,
      brand: data.brand,
      prepaid: data.prepaid,
      cod: data.cod
    }

    return this.httpClient.post(this.apiBaseURLNode + `get-count/add-catalogue`, datas);
  }

  public getCatalogVendor(page, limit): Observable<any> {

    return this.httpClient.get(this.apiBaseURLNode + `get-count/get-catalogue?vendorId=${localStorage.getItem('vendor_storeId')}&page=${page}&limit=${limit}`);
  }

  public editCatalog(data, attribute, name, image): Observable<any> {

    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'editCatalog',
        param: {
          unqId: data.unqId,
          title: data.title,
          category: [`${name}`],
          price: data.price,
          description: data.description,
          quantity: data.quantity || 0,
          offer_price: data.offer_price,
          is_catalog: true,
          is_deal: data.deal_item,
          prepaid: data.prepaid,
          cod: data.cod,
          status: data.status,
          images: image,
          tax: data.tax,
          attribute: attribute,
          brand: data.brand
        }
      });

  }


  public fileEdit(data) {
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.post(`${environment.apiUrl}file_upload_common.php`, data, { headers });
    // return this.httpClient.post(`https://moonhive-server.in.net/core-php/kko/v1/file_upload_common.php`, data, {headers});
  }


  // public getCatalogue(data,pageNo,limitNo): Observable<any> {
  //   return this.http.post(`${this.apiBaseUrl}`,
  //   {
  //   name:'listCatalogdashboard',
  //   param:{
  //     storeId:data,
  //     page : pageNo,
  //     limit: limitNo,
  //     search_key: ''
  //   }
  // });

  //  }

  public getCatalogue(storeId, page, limit, key): Observable<any> {
    return this.httpClient.get(this.apiBaseURLNode + `get-count/get-catalogue-admin?store_ID=${storeId}&page=${page}&limit=${limit}&key=${key}`);
  }

  public getDealVendor(storeId, vendorId, page, limit): Observable<any> {
    return this.httpClient.get(this.apiBaseURLNode + `vendor/get-vendor-deal?store_ID=${storeId}&vendorId=${vendorId}&page=${page}&limit=${limit}`);
  }

  public deleteVendorCatalogue(unqId): Observable<any> {
    return this.httpClient.get(this.apiBaseURLNode + `vendor/delete-vendor-catalogue?unqId=${unqId}`);
  }

  public getDealOfDay(data, pageNo, limitNo, key): Observable<any> {
    let param = {
      "type": "is_deal",
      "storeId": data,
      "limit": limitNo,
      "page": pageNo,
      "search_key": key
    }
    // return this.http.post(`${this.apiBaseUrl}`,
    //   {
    //     name: 'listOffersdashboard',
    //     param: {
    //       storeId: data,
    //       page: pageNo,
    //       limit: limitNo,
    //       search_key: key
    //     }
    //   });
    return this.http.post(this.apiBaseURLNode + `get-count/listoffersdashboard`, param)
  }

  public statusCatalog(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'statusCatalog',
        param: {
          id: data,
        }
      });
  }

  public getDeliveryBoy(data, pageNo, limitNo): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'listDboy',
        param: {
          storeId: data,
          page: pageNo,
          limit: limitNo
        }
      });

  }
  public deliveryDelete(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'deleteDboy',
        param: {
          id: data,
        }
      });

  }

  public editDelivery(data) {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'editDboy',
        param: data
      });
  }


  public getCoupon(data, pageNo, key): Observable<any> {
    let param = {
      storeId: data,
      page: pageNo,
      searchkey: key,
      limit: 10
    }
    // return this.http.post(`${this.apiBaseUrl}`,
    //   {
    //     name: 'listCoupon',
    //     param: {
    //       storeId: data,
    //       page: pageNo,
    //       searchkey: key,
    //       limit: 10
    //     }
    //   });
    return this.http.post(`${this.apiBaseURLNode}` + 'coupon/listcoupon', param)
  }

  public CouponDelete(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'deleteCoupon',
        param: {
          unqId: data
        }
      });

  }

  public editCoupon(data) {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'editCoupon',
        param: data
      });
  }



  public getBanner(data, pageNo, key): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'listBanner',
        param: {
          storeId: data,
          page: pageNo,
          limit: 10,
          searchkey: key
        }
      });

  }


  public bannerDelete(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'deleteBanner',
        param: {
          unqId: data
        }
      });
  }

  public bannerEdit(data, image): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'editBanner',
        param: {
          storeId: localStorage.getItem('storeunqId'),
          unqId: data.id,
          area: data.area,
          title: data.title,
          carousel_check: data.carousel_check,
          image: image || data.file
        }
      });
  }


  public deleteDeal(data): Observable<any> {
    let param = {
      id: data
    }
    return this.http.post(this.apiBaseURLNode + 'get-count/dealsupdate', param)
    // return this.http.post(`${this.apiBaseUrl}`,
    //   {
    //     name: 'DealsUpdate',
    //     param: {
    //       id: data
    //     }
    //   });
  }

  public deleteCatalogue(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}`,
      {
        name: 'DealsUpdate',
        param: {
          id: data,
          is_catalogue: true
        }
      });
  }

  getProductStatitics(data) {
    return this.httpClient.get(this.apiBaseURLNode + `order/productOrder?prodId=${data.prodId}&storeId=${data.storeId}`);
  }

  updateProduct(data) {
    return this.httpClient.post(this.apiBaseURLNode + `order/productOrder`, data);
  }

}
