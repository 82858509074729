<mat-sidenav-container  class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="'over'" [opened]="false">

      <mat-nav-list>
        <ng-container>
        <a mat-list-item>
          <div class="sidenav-item" routerLink='' routerLinkActive="active-nav-item">
            <span style="margin-right: 20px;">
              <img src="" alt="icon">
            </span>
            <span>name</span>
          </div>
        </a>
        <ngb-accordion #acc="ngbAccordion" >
          <ngb-panel >
            <ng-template ngbPanelTitle style="display: flex;" >
              <span style="margin-right: 20px;">
                <img src="" alt="icon">
              </span>
              <span>name</span>
            </ng-template>
            <ng-template  ngbPanelContent>
              <a routerLinkActive="active-nav-subItem" mat-list-item
                routerLink="">
                <div style="padding-left: 60px;" class="sidenav-item">
                  <span>name</span>
                </div>
              </a>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        </ng-container>

      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>

  </mat-sidenav-container>
