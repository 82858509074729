import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {path: '', redirectTo: 'guest', pathMatch: 'full'},
      {path: 'guest', loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule)},
      {path: '', redirectTo: 'admin', pathMatch: 'full'},
      {path: 'admin', loadChildren:  () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
    ]
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }