
    <div class="card text-white mb-4" [ngClass]="config.bgColor">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
                <div class="mr-3">
                    <div class="text-white-75 small">{{config.cardHead}}</div>
                </div>
                <ng-container *ngIf="config.fa_icon === 'overdue'">
                         <i class="fas fa-book text-white-50" style='font-size:36px;'></i>
                </ng-container>
                <ng-container *ngIf="config.fa_icon === 'order'">
                    <i class="fas fa-shopping-cart text-white-50" style='font-size:36px;'></i>
                </ng-container>
                <ng-container *ngIf="config.fa_icon === 'users'">
                    <i class="fas fa-users text-white-50" style='font-size:36px;'></i>
                </ng-container>
                <ng-container *ngIf="config.fa_icon === 'money-sign'">
                    <i class="fa-solid fa-indian-rupee-sign text-white-50" style='font-size:36px;'></i>
                </ng-container>
            </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-between">
            <ng-container *ngIf="config.isOrderLink === true">
                <a class="small text-white" routerLink="{{config.routeLink}}" href="">{{config.cardLink}}</a>
            </ng-container >
            <ng-container *ngIf="config.isTotalCount === true">
              <a class="small text-white">{{config.cardLink}}</a>
          </ng-container >
            <ng-container *ngIf="config.isFinanceLink === true">
                <a class="small text-white" routerLink="{{config.routeLink}}" href="">{{config.cardLink}}</a>
            </ng-container >
            <div class="small text-white justify-content-end">
                <div class="text-lg font-weight-bold ">
                        <ng-container *ngIf="config.icon === 'rupee'">
                                <i class="fas fa-rupee-sign"></i> {{config.cardFooter}}
                        </ng-container >
                        <ng-container *ngIf="config.icon !== 'rupee'">
                           {{config.cardFooter}}
                        </ng-container >
                </div>
            </div>
        </div>
    </div>

