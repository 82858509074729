import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreDatasService } from 'src/app/store-datas.service';
import { VendorService } from 'src/app/modules/admin/services/vendor.service';
import { StoreDetailsService } from 'src/app/modules/admin/services/store-details.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {

  public venderForm: FormGroup;
  public vendorDetail: any = true;
  public vendorStoreType: any= false;
  public storeForm: FormGroup;
  public adminStore: any;
  public vendorUserId: any;
  public submitted: any = false

  constructor(private formBuilder: FormBuilder ,private router: Router, private storeDetail: StoreDatasService, 
    private vendorService: VendorService, private storeDetailsService : StoreDetailsService, private toastr: ToastrService) { 
    this.storeDetail.sideNav$.next(false);


    this.venderForm = this.formBuilder.group({
      vendorName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^[a-z]+([.-]?[a-z0-9]+)*@([a-z]+([.-]?[a-z]))[.]{1}[a-z]{2,}$")]],
      streetAddress: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("[0-9]+")]], 
      regNumber:['', [Validators.required, ]],
      gstin:['', [Validators.required,]],
      country:['',Validators.required],
      state:['',Validators.required],
      city:['',Validators.required],
      pincode: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(6)]],
    })

    this.storeForm = this.formBuilder.group({
      store: ['', [Validators.required, Validators.pattern(/^[^\\]+$/)]],
     })


  }

  ngOnInit() {
    this.vendorUserId= localStorage.getItem('userId')
    this.adminStore = localStorage.getItem('adminStore')
    this.storeDetail.sideNav$.next(false);
  }


  venderFormSubmit(){
    if(!this.venderForm.valid) {
        this.submitted=true
    } else {
      this.vendorDetail= false
      this.vendorStoreType= true
    }
    
  }
  cancelBuild(data){
    if(data == 'detail') {
      this.venderForm.reset()
    } else {
      this.vendorDetail= true
      this.vendorStoreType= false
    }
  }
  navigateToNextPage() {
    
  }

  storeDetails() {
      this.storeDetailsService.storeDetails(this.adminStore).subscribe((res: any) => {
      });
  }


  vendorDetailSubmit() {
    let data= {
      userCategory: 'vendor',
      vendorUserId: this.vendorUserId,
      storeId: this.adminStore,
      userId: localStorage.getItem('userId'),
      vendorName: this.venderForm.value.vendorName,
      phone: this.venderForm.value.phone,
      email: this.venderForm.value.email,
      regNumber: this.venderForm.value.regNumber,
      gstin: this.venderForm.value.gstin,
      streetAddress: this.venderForm.value.streetAddress,
      country: this.venderForm.value.country,
      state: this.venderForm.value.state,
      city: this.venderForm.value.city,
      pincode: this.venderForm.value.pincode,
      storeType: this.storeForm.value.store

    }

    this.vendorService.addVendor(data).subscribe((res: any)=> {
      if(res.status == 200) {
        this.router.navigate(['/admin'])
      }else {
        this.copyTextWar('Phone number or email Already exist')
      }
    })
    
  }

  copyTextWar(data: any) {
    this.toastr.warning(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }
}