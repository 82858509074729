import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-inner-nav',
  templateUrl: './inner-nav.component.html',
  styleUrls: ['./inner-nav.component.scss']
})
export class InnerNavComponent  {

  @ViewChild('drawer') drawer: any

  isHandset$: Observable<boolean>
  = this.breakpointObserver.observe(['(max-width: 1500px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    )
  userInfo: any;
  config: MatDialogConfig = {

  }
  userOrgs: any;
  SelectOrg: any
  selectedOrgDetail: any;
  fired: boolean = false;
  selectedNavItem: string
  isHandset: boolean;
  orgList: any;
  dropMenuState:boolean  = false
  roles = [];
  navItems: any;
  innerNavState: any;


  constructor(private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog, private router:Router) {

  }


}
