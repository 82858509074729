import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdduserService } from 'src/app/modules/admin/services/adduser.service';
import { StoreDetailsService } from 'src/app/modules/admin/services/store-details.service';
import { StoreDatasService } from 'src/app/store-datas.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  public isCollapsed = true;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;
  public isCollapsed7 = true;
  public StoreID: any = localStorage.getItem('userId');
  public StoreName: any = localStorage.getItem('userName');
  public sideNav: any;
  public storeID: any = localStorage.getItem('storeunqId');
  logoutPrombt = 'none';
  public getScreenWidth: any;
  public getScreenHeight: any;
  convertedData: any;
  userType: string;

  constructor(private storeDatas: StoreDatasService, private storeDetailsService: StoreDetailsService, public router: Router, public adduserService: AdduserService) {
    this.userType = localStorage.getItem('userCategory')
    this.storeDatas.storeDetails$.subscribe((data) => {
      this.storeID = data;
      this.menuList()
    });
    this.StoreName = localStorage.getItem('userName');

    this.storeDatas.storeMenuList$.subscribe((data) => {
      if (data == true) {
        this.menuList()
      }
    })
  }
  handleCollapse(id) {
    switch (id) {
      case 1:
        this.isCollapsed1 = !this.isCollapsed1
        this.isCollapsed2 = true
        this.isCollapsed3 = true
        this.isCollapsed4 = true
        this.isCollapsed5 = true
        this.isCollapsed6 = true
        this.isCollapsed7 = true

        break;
      case 2:
        this.isCollapsed2 = !this.isCollapsed2
        this.isCollapsed1 = true
        this.isCollapsed3 = true
        this.isCollapsed4 = true
        this.isCollapsed5 = true
        this.isCollapsed6 = true
        this.isCollapsed7 = true

        break;
      case 3:
        this.isCollapsed3 = !this.isCollapsed3
        this.isCollapsed2 = true
        this.isCollapsed1 = true
        this.isCollapsed4 = true
        this.isCollapsed5 = true
        this.isCollapsed6 = true
        this.isCollapsed7 = true

        break;
      case 4:
        this.isCollapsed4 = !this.isCollapsed4
        this.isCollapsed2 = true
        this.isCollapsed3 = true
        this.isCollapsed1 = true
        this.isCollapsed5 = true
        this.isCollapsed6 = true
        this.isCollapsed7 = true

        break;
      case 5:
        this.isCollapsed5 = !this.isCollapsed5
        this.isCollapsed2 = true
        this.isCollapsed3 = true
        this.isCollapsed1 = true
        this.isCollapsed4 = true
        this.isCollapsed6 = true
        this.isCollapsed7 = true

        break;
      case 6:
        this.isCollapsed6 = !this.isCollapsed6
        this.isCollapsed2 = true
        this.isCollapsed3 = true
        this.isCollapsed1 = true
        this.isCollapsed4 = true
        this.isCollapsed5 = true
        this.isCollapsed7 = true
        break;
        case 7:
          this.isCollapsed7 = !this.isCollapsed7
          this.isCollapsed2 = true
          this.isCollapsed3 = true
          this.isCollapsed1 = true
          this.isCollapsed4 = true
          this.isCollapsed5 = false
          this.isCollapsed6 = true
          break;
  
      default:
      // code block
    }
  }

  ngOnInit() {
    this.onWindowResize()
    this.storeDatas.responsive.next(true)
    this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
    this.menuList();
    this.StoreName = localStorage.getItem('userName');
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth > 991) {
      document.getElementById('sNav').style.display = 'block'
      document.getElementById('openSN').style.display = 'none'
      document.getElementById('mainNav').style.width = '255px'
    }else{
      document.getElementById('sNav').style.display = 'none'
    document.getElementById('mainNav').style.width = '15px'
    document.getElementById('openSN').style.display = 'block'
    }
  }

  menuList() {
    if(localStorage.getItem('userRole') == '' || localStorage.getItem('userRole') == undefined || localStorage.getItem('userRole') == null) {
      this.storeDetailsService.menuListing(this.storeID).subscribe((res: any) => {
      this.sideNav = res.response.result.sidenav;
    })
    } else {
      if(localStorage.getItem('userCategory') == 'vendor') {
        this.adduserService.getRoles('vendor').subscribe((res: any)=>{
        this.convertedData = JSON.parse(res[0].json)
        this.sideNav= this.convertedData.sidenav
      })
      } else {
        this.adduserService.getRoles(localStorage.getItem('userRole')).subscribe((res: any)=>{
          this.convertedData = JSON.parse(res[0].json)
          this.sideNav= this.convertedData.sidenav
        })
      } 
      
      
    }
  }

  logout() {
    this.storeDatas.logout.next(true);
  }

  closeSidenav() {
    this.storeDatas.responsive.next(false)
    document.getElementById('sNav').style.display = 'none'
    document.getElementById('mainNav').style.width = '15px'
    document.getElementById('openSN').style.display = 'block'
  }

  openSidenav() {
    this.storeDatas.responsive.next(true)
    document.getElementById('sNav').style.display = 'block'
    document.getElementById('mainNav').style.width = 'auto'
    document.getElementById('openSN').style.display = 'none'
  }
  
}
