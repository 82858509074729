import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  private apiBaseUrl = `${environment.apiUrl}`;
  private nodeUrl =`${environment.apiUrlNode}`

  constructor(private http: HttpClient) { }

  // public forgotPassword(data: any) {
  //   return this.http.post(`${this.apiBaseUrl}`,
  //   {
  //     name:'forgetPassword',
  //     param: {
  //       email: data
  //     }
  //   });
  // }

  public forgotPassword(data: any) {
    return this.http.post(`${this.nodeUrl}auth//forgot-password`,data);
  }

  // public resetPassword(data: any, userId) {
  //   return this.http.post(`${this.apiBaseUrl}`,
  //   {
  //     name: 'updatePassword',
  //     param: {
  //       userId: userId,
  //       // userId: "DSU1654251216",
  //       password: data
  //     }
  //   })
  // }

  public resetPassword(pass: any, userId:any) {
    let data={
      userId: userId,
      password: pass
    }
    return this.http.post(`${this.nodeUrl}auth/reset-password`,data);
  }

}
