<nav class="topnav navbar navbar-expand new-shadow navbar-light bg-white" id="sidenavAccordion">
    <a class="navbar-brand">
      <img rel="icon" src="assets/images/logo (1).png" style="height: 33px; width: 127px;"><span
        class="pt-2"></span>
    </a>
  </nav>

<div style="margin: 25px 25px 50px 25px !important;">
    <div class="row">
        <div div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
            <div class="card">
                <div class="card-header">
                    <div class="store-details-card-header">
                        <div >
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="store-details-card-title card-title">
                                        <small class="v1">Vendor details</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <form [formGroup]="venderForm" #myform="ngForm">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="vendorName"> Vendor Name*</label>
                                            <input class="form-control form-control-sm " id="vendorName" type="text"
                                                formControlName="name" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('name').hasError('required') && venderForm.get('name').touched"
                                                class='error-message text-danger'>Vendor name is required</span>
                                            <span
                                                *ngIf="venderForm.get('name').hasError('pattern') && venderForm.get('name').touched"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label class="small" for="inputPhoneNumber"
                                                    style="margin-bottom: 4px;">Phone
                                                    Number*</label>
                                                <div>
                                                    <div class="inputicons">
                                                        <input formControlName="phone1" type="text"
                                                            class="form-control form-control-sm phone"
                                                            id="inputPhoneNumber" required />
                                                        <span
                                                            *ngIf="venderForm.get('phone1').hasError('required') && venderForm.get('phone1').touched"
                                                            class='error-message text-danger'>Phone number is
                                                            required</span>
                                                        <span
                                                            *ngIf="venderForm.get('phone1').hasError('pattern') && venderForm.get('phone1').touched"
                                                            class='error-message text-danger'>Enter a valid 10-digit phone number</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputMailId">Email address*</label>
                                        <input class="form-control form-control-sm " id="inputMailId" type="email"
                                            formControlName="email" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('email').hasError('required') && venderForm.get('email').touched"
                                            class='error-message text-danger'>E-mail is required</span>
                                        <span
                                            *ngIf="venderForm.get('email').hasError('pattern') && venderForm.get('email').touched"
                                            class='error-message text-danger'>Enter the valid Email</span>
                                    </div>

                                </div>
                                <div class="card-header-underline1"></div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputRegNumber"> Store Reg Number*</label>
                                            <input class="form-control form-control-sm " id="inputRegNumber" type="text"
                                                formControlName="store" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('store').hasError('required') && venderForm.get('store').touched"
                                                class='error-message text-danger'>Store reg number is required</span>
                                            <span
                                                *ngIf="venderForm.get('store').hasError('pattern') && venderForm.get('store').touched"
                                                class='error-message text-danger'>Store reg number must be between 5 to 10 alphanumeric characters
                                               </span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label class="small" for="inputGstinNumber"> GSTIN Number*</label>
                                                <input class="form-control form-control-sm " id="inputGstinNumber"
                                                    type="text" formControlName="gstin" style="margin-top: -4px;"
                                                    required />
                                                <span
                                                    *ngIf="venderForm.get('gstin').hasError('required') && venderForm.get('gstin').touched"
                                                    class='error-message text-danger'>GSTIN number is required</span>
                                                <span
                                                    *ngIf="venderForm.get('gstin').hasError('pattern') && venderForm.get('gstin').touched"
                                                    class='error-message text-danger'>Enter a valid 15-character GSTIN number</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header-underline2"></div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="streetAddress"> Street Address*</label>
                                            <input class="form-control form-control-sm " id="streetAddress" type="text"
                                                formControlName="street" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('street').hasError('required') && venderForm.get('street').touched"
                                                class='error-message text-danger'>Street address is required</span>
                                            <span
                                                *ngIf="venderForm.get('street').hasError('pattern') && venderForm.get('street').touched"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label class="small" for="inputCountryName"> Country*</label>
                                                <input class="form-control form-control-sm " id="inputCountryName"
                                                    type="text" formControlName="country" style="margin-top: -4px;"
                                                    required />
                                                <span
                                                    *ngIf="venderForm.get('country').hasError('required') && venderForm.get('country').touched"
                                                    class='error-message text-danger'> Country is required</span>
                                                <span
                                                    *ngIf="venderForm.get('country').hasError('pattern') && venderForm.get('country').touched"
                                                    class='error-message text-danger'>Special character and double space
                                                    are
                                                    not allowed</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputStateName"> State*</label>
                                            <input class="form-control form-control-sm " id="inputStateName" type="text"
                                                formControlName="state" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('state').hasError('required') && venderForm.get('state').touched"
                                                class='error-message text-danger'>State is required</span>
                                            <span
                                                *ngIf="venderForm.get('state').hasError('pattern') && venderForm.get('state').touched"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputCityName"> City*</label>
                                        <input class="form-control form-control-sm " id="inputCityName" type="text"
                                            formControlName="city" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('city').hasError('required') && venderForm.get('city').touched"
                                            class='error-message text-danger'>City is required</span>
                                        <span
                                            *ngIf="venderForm.get('city').hasError('pattern') && venderForm.get('city').touched"
                                            class='error-message text-danger'>Special character and double space are
                                            not allowed</span>
                                    </div>
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputPinNo"> Pincode*</label>
                                        <input class="form-control form-control-sm " id="inputPinNo" type="text"
                                            formControlName="pincode" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('required') && venderForm.get('pincode').touched"
                                            class='error-message text-danger'>Pincode is required</span>
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('maxLength') && venderForm.get('pincode').touched"
                                            class='error-message text-danger'>You can only enter 6 digit pincode</span>
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('minLength') && venderForm.get('pincode').touched"
                                            class='error-message text-danger'>Pincode must be 6 digit</span>
                                        
                                    </div>

                                </div>
                                <div class="button-container">
                                    
                                    <button class="button button4"(click)="cancelBuild()">Cancel</button>
                                    
                                    <button class="button  button5" (click)="navigateToNextPage()">Nextss</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>