import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateNewStoreService {

  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseUrlNode = `${environment.apiUrlNode}`;
  public StoreID: any = localStorage.getItem('storeunqId');

  constructor(private http: HttpClient) { }


  public storeName(name) {
    return this.http.get(this.apiBaseUrlNode+`storeName?name=${name}`)
  }
 
  // to add the store sub domain
  public addStoreSubDomain(unqId) {
    return this.http.get(this.apiBaseUrlNode+`storeName/storeDomain?unqId=${unqId}`)
  }

  // public planDetails() {
  //   return this.http.get(this.apiBaseUrlNode+`key`)
  // }

  public createNewStore(data: any, uId: any, type: any, plan: any,isTrail:any, expiry:any, logo) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'createStoreNew',
      param:{
        uid: uId,
        name:data.name,
        email:data.email,
        address:data.address,
        phone1:data.phone1,
        phone2:data.phone2 || "",
        pincode:data.pincode,
        city:data.city,
        country:data.country,
        location:data.location,
        type: type.type,
        plan: plan,
        isTrail:isTrail,
        expireyDate: expiry,
        logo: logo
      }
    });
  }

  public getAllCountries(data) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'countries',
      param:{
        key: data
      }
    });
  }

  public razorpayKey() {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name: 'getRazorpayDetails',
      param: {
        storeId: this.StoreID
      }
    })
  }
}
