<div class="table-container">
    <table class="table">
        <thead>
            <tr>
                <!-- <th
                    *ngIf="itemsPerPage">#</th> -->
                <th 
                    *ngFor="let e of config.coloumns">{{e.title | uppercase }}</th>
                <th  *ngIf="config.actions">
                    ACTIONS</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let row of data; let i = index">
                <!-- <td  *ngIf="itemsPerPage">{{(itemsPerPage *
                    (currentPage - 1)) + (i + 1)}}</td> -->
                <!--Table Data-->
                <td  *ngFor="let cell of config.coloumns">
                    <ng-container *ngIf="cell.type === 'images'">
                        <img src="{{row[cell.field]}}" style="width: 30px; height: 30px; border-radius: 50px;" />
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'description'">
                        <p>{{(row.description.length > 30) ? (row.description | slice:0:30)+ '...':
                            (row.description)}}</p>
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'modal'">
                        <a href="#" data-toggle="modal" data-target="#orderDetailModal"
                            (click)="orderModal(row)">{{row[cell.field]}}</a>
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'editTrackModal'">
                        <a href="#" data-toggle="modal" data-target="#shippingEditModal"
                            (click)="trackEditModal(row)">{{row[cell.field]}}</a>
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'deliveryBoyDetails'">
                        <p>{{row[cell.field]?.name ? row[cell.field].name : "--"}} {{row[cell.field]?.last_name}}</p>
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'created_On'">
                        <p>{{row[cell.field] | date: 'MM/dd/yyyy'}}</p>
                    </ng-container>
                    <ng-container *ngIf="cell.field === 'created_on'">
                        <p>{{row[cell.field] | date: 'MM/dd/yyyy'}}</p>
                    </ng-container>
                    <ng-container *ngIf="cell.type === 'userModal'">
                        <a href="#" data-toggle="modal" data-target="#userDialog"
                            (click)="userDetilModal(row)">{{row[cell.field]}}</a>
                    </ng-container>
                    <ng-container *ngIf="cell.icon === 'rupee'">
                        ₹{{row[cell.field]}}
                    </ng-container>
                    <ng-container *ngIf="cell.field == 'DeliveryBoy'">
                        <select class="form-control form-control-sm custom-select" id="exampleFormControlSelect1"
                            (change)="selectBoy($event.target.value,row)">
                            <option>Select Delivery Boy</option>
                            <option *ngFor="let select of DBoy" value={{select.id}}>
                                {{select.name}}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="cell.statusbtn  === 'status_btn ' && row[cell.field] === 'active'">
                        <button class="btn btn-sm   btn -info btn -sm" (click)="changeUserStatus(row)">inactive</button>
                    </ng-container>
                    <ng-container *ngIf="cell.statusbtn  === 'status_btn ' && row[cell.field] === 'inactive'">
                        <button class="btn btn-sm btn -info btn -sm" (click)="changeUserStatus(row)">active</button>
                    </ng-container>
                    <ng-container *ngIf="cell.openOrderStatus == 'true'">
                        <select [disabled]="hideButtons" class="form-control form-control-sm detailsOption custom-select"
                            id="exampleFormControlSelect1" (change)="openModal($event.target.value,row)">
                            <option *ngFor="let select of config.Status" value={{select.option}}>
                                {{select.option}}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container
                        *ngIf="cell.title != 'AssignDelivery' && cell.type != 'modal' && cell.type != 'userModal' && cell.icon != 'rupee' && cell.statusbtn  != 'status_btn ' && cell.type != 'deliveryBoyDetails' && cell.type != 'createdOn' && cell.type != 'images' && cell.type != 'editTrackModal' && cell.type != 'description' && cell.field != 'created_on' && cell.type != 'created_On'">
                        {{row[cell.field]}}
                    </ng-container>

                </td>
                <td *ngIf="!config.actions?.status == true">

                    <div style="position: relative;">
                        <!-- <div *ngIf="subMenuList.subCategory">
                            <div *ngIf="config.actions?.add == true">
                                <a href="#" class="edit-btn  mr-3" data-toggle="modal"
                                    data-target="{{config.actions.addModalName}}" (click)="addPrombt(row)">Add
                                    Sub-category</a>
                            </div>
                        </div> -->
                        <div (click)="openPopup(i).stopPropagation()"
                            *ngIf="config.actions?.edit == true || config.actions?.delete == true"
                            style="cursor: pointer;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </div>
                        <div style="position: absolute; top:-13px; right: 25px;" *ngIf="showPopup == i">
                            <div class="modal" style="display: block; position: relative; border: none;" tabindex="-1"
                                role="dialog">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content mooodal"
                                        style="margin-top: -25px;padding-left: 10px; width: 100px; border: none;background: #FFFFFF; box-shadow: 0px 1px 12px rgb(137 137 137 / 25%);">
                                        <div *ngIf="config.actions?.edit == true">
                                            <button href="#" class="btn edit-button" style="display: flex;
                                            padding-left: 0;" data-toggle="modal"
                                                data-target="{{config.actions.editModalName}}"
                                                (click)="editPrombt(row)"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path
                                                        d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
                                                        fill="#11AB2E" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Edit</button>
                                        </div>

                                        <div *ngIf="config.actions?.delete == true">
                                            <button class="btn delete-button" style="padding: 5px 0px;
                                            display: flex;
                                            justify-content: flex-start;"
                                                data-target="{{config.actions.deleteModalName}}"
                                                (click)="deletePrombt(row)"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path
                                                        d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
                                                        fill="#11AB2E" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>

                <td *ngIf="config.actions?.status == true">
                    <div style="position: relative;">
                        <div (click)="openPopup(i)" style="cursor: pointer;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </div>
                        <div style="position: absolute; top:-25px; right: 25px;" *ngIf="showPopup == i">
                            <div class="hover_bkgr_fricc">
                                <div class="modal" style="display: block; position: relative; border: none;"
                                    tabindex="-1" role="dialog" config="{backdrop: 'static',  keyboard: false}">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content mooodal"
                                            style="margin-top: -25px; width: 100px; border: none;background: #FFFFFF; box-shadow: 0px 1px 12px rgb(137 137 137 / 25%);">
                                            <button href="#" class="btn edit-button" data-toggle="modal"
                                                data-target="{{config.actions.editModalName}}"
                                                (click)="editPrombt(row)"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path
                                                        d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
                                                        fill="#11AB2E" />
                                                </svg>&nbsp;&nbsp;&nbsp;Edit</button>
                                            <!-- <ng-container *ngIf="row.status === 'inactive'">
                        <button class="btn btn-primary"
                            (click)="catalogStatusChange(row)">Unpublish</button>
                    </ng-container>
                    <ng-container *ngIf="row.status === 'active'">
                        <button class="btn btn-primary"
                            (click)="catalogStatusChange(row)">Publish</button>
                    </ng-container> -->
                                            <button class="btn delete-button" style="padding: 5px 8px;
                                        display: flex;
                                        justify-content: flex-start;" (click)="deletePrombt(row)"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 20 20" fill="none">
                                                    <path
                                                        d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
                                                        fill="#11AB2E" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Delete</button>
                                            <button class="btn edit-button" style="padding: 5px 8px;
                                            display: flex;
                                            justify-content: flex-start;" (click)="sharePrombt(row)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                                                    <path
                                                        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Share</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </td>
            </tr>

        </tbody>
    </table>

</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayBannerDelete }" #bannerDeleteModal>
    <div class="modal-dialog displayModal" role="document">
        <div class="modal-content" style="max-width: 350px; margin-left: 425px; margin-top: 240px;">
            <div class="modal-body modal-scroll mx-auto">
                <h3 class="mt-4 text-center">Are you sure?</h3>
                <form>
                    <div class="form-group mt-4">
                        <div class="row mb-2 pb-3 justify-content-center">
                            <button class="btn btn-sm btn-danger mr-4" type="submit" (click)="onClose()">
                                Yes
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>