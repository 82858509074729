<div>
  <div class="alert alert-success alert-dismissible fade show" role="alert" style="z-index: 99;" *ngIf = "config?.status == 200">
      <button #toastAlertClose  type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
    <strong>{{config.result}} {{config.message}}</strong>  
  </div>
  <div class="alert alert-danger alert-dismissible fade show" role="alert" style="z-index: 1;" *ngIf = "config?.status == 500">
    <button #toastAlertClose type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config.result}}</strong>  
  </div>
  <div class="alert alert-danger alert-dismissible fade show" role="alert" style="z-index: 1;" *ngIf = "config?.status == 500">
    <button #toastAlertClose type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config.message}}</strong>  
  </div>
  <div class="alert alert-danger alert-dismissible fade show" role="alert" style="z-index: 1;" *ngIf = "config?.status == 103">
    <button #toastAlertClose type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config.message}}</strong>  
  </div>
  <div class="alert alert-warning alert-dismissible fade show" role="alert" style="z-index: 1;" *ngIf = "config?.status == 409">
    <button #toastAlertClose type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config.result}}</strong>  
  </div>
  <div class="alert alert-warning alert-dismissible fade show" role="alert" style="z-index: 1;" *ngIf = "config?.status == 404">
    <button #toastAlertClose type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config.result.message}}</strong>  
  </div>
  <!-- <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf = "config">
    <button type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
   <strong>{{config}}</strong>  
  </div> -->
    <!-- <button #toastAlertClose type="button" class="close" data-dismiss="alert">&times;</button>
    <strong>{{config.result}}</strong>   -->


    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf = "config?.error">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
       <strong>{{config.result}}</strong>  
      </div>

    <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf = "config?.Success?.status == 200">
      <button #toastAlertClose  type="button" class="close" data-dismiss="alert" (click)="alertDismissed()">&times;</button>
    <strong>{{config.Success.message}}</strong>  
  </div>
  <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf = "config?.Success?.status == 500">
    <button #toastAlertClose  type="button" class="close" data-dismiss="alert">&times;</button>
  <strong>{{config.Success.message}}</strong>  
</div>
  
</div>