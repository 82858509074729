<div class="d-flex justify-content-between">
  <div class="col-lg-6 mx-auto loginContent">
    <!-- Basic login form-->
    <div class="description" style="margin-top: 80px; margin-left: 60px; margin-right: 45px;">Welcome back - log in to Dailystore pick up right where you left off!
    </div>
    <div style="text-align: center; margin-top: 115px;">
      <img src="assets/images/otp-login.png" style="width: 60%;">
    </div>
  </div>



  <div class="col-lg-6 mx-auto coloum-2" style="margin-top: -90px;
    background: #FFF;
    min-height: 100vh;
    -webkit-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
  -moz-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
  box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
  ">
    <div style="text-align: center; margin-top: 147px;" class="green_logo"><img src="assets/images/green_logo.png"></div>
    <!-- Basic login form-->
    <div class="newstyle">
    <div class="col-2-section">
      <div class="col-2-heading">Login!</div>
      <div class="card-body">
        <app-toast [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>
        <!-- Login form-->
        <form [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()" #myform="ngForm">
          <!-- Form Group (phone number)-->
          <div class="form-group">
            <mat-form-field appearance="outline" class="matDesign">
              <mat-label style="font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.94px;
            color: black;
              " for="inputMobileNumber">Mobile Number</mat-label>
              <input matInput placeholder="Enter PhoneNumber" id="inputMobileNumber" type="text" (keypress)="onlyNumberKey($event)"
                formControlName="phone" required />
            </mat-form-field>
            <span *ngIf="loginForm.get('phone').hasError('required') && (loginForm.get('phone').touched || submitted)"
              class='error-message text-danger'>Mobile number is required</span>
            <span *ngIf="loginForm.get('phone').hasError('pattern') && loginForm.get('phone').touched"
              class='error-message text-danger'>Enter the valid phone number</span>
          </div>
          <div class="d-flex justify-content-end" style="margin-bottom: 20px; margin-top: 0px;">
            <div><a routerLink="/guest/forgot_password"  class="password" href="">Reset Password?</a></div>
          </div>
          <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
            <button class="btn container loginotp"  type="submit"
              >Login</button>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-end" style="margin-bottom: 37px; margin-right: 20px;">
        <div><a (click)="goToLogin()" style=" cursor: pointer" class="password1">Login using Password</a></div>
      </div>
      <div style="text-align: center;">
        <div class="signup">Already have an account? <a (click)="goToSignin()" style="color: #4CAF50; cursor: pointer">&nbsp;Sign
            Up</a></div>
      </div>
      <div class="terms" style="text-align: center; color: rgba(146, 146, 146, 1);">
        By continuing, you agree to our Terms of Service and Privacy Policy
      </div>
    </div>

    
    </div>
  </div>
</div>


