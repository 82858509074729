<header class="page-header page-header-dark bg-gradient-primary-to-secondary">
        <div class="container">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto mt-5">
                        <h3 class="page-header-title">
                            <div class="page-header-icon">
                                <ng-container *ngIf="config.icon === 'activity'">
                                    <i data-feather="activity"></i>
                                </ng-container>
                                <ng-container *ngIf="config.icon === 'tool'">
                                        <i data-feather="tool"></i>
                                    </ng-container>
                                <ng-container *ngIf="config.icon === 'list'">
                                    <i data-feather="list"></i>
                                </ng-container>
                                <ng-container  *ngIf="config.icon === 'settings'">
                                        <i data-feather="settings"></i>
                                </ng-container>
                                <ng-container  *ngIf="config.icon === 'dollar-sign'">
                                    <i data-feather="dollar-sign"></i>
                            </ng-container>
                                <ng-container  *ngIf="config.icon === 'users'">
                                        <i data-feather="users"></i>
                                </ng-container>
                                <ng-container  *ngIf="config.icon === 'shopping-bag'">
                                    <i data-feather="shopping-bag"></i>
                            </ng-container>
                            <!-- <ng-container  *ngIf="config.icon === 'add-users'">
                                <i data-feather="adduser"></i>
                        </ng-container> -->
                            </div>
                            {{config.title}}
                        </h3>
                        <div class="page-header-subtitle">{{config.description}}</div>
                    </div>
                </div>
            </div>
        </div>
</header>