import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../modules/admin/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpServiceService } from 'src/app/modules/admin/services/otp-service.service';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap'
import { EMPTY, from, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AdduserService } from 'src/app/modules/admin/services/adduser.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/modules/admin/services/header.service';


declare const FB: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public alertLoginData: any;
  public status: any;
  public result: any;
  public isApiReturned: boolean;
  public authentication: any;
  public storeId: any;
  public toastConfig: any;
  public submitted: any = false;
  googleData: any;
  fbData: Object;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public http: HttpClient,
    private otpService: OtpServiceService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private adduserService: AdduserService,
    private toastr: ToastrService,
    public headerService: HeaderService,
  ) {
    this.loginForm = this.formBuilder.group(
      {
        phone: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$')]],
        password: ['', Validators.required]
      }
    )
  }

  ngOnInit() {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: '351984361500-re7l8uu0o5uvvp2jrdep73iaf6ajss5j.apps.googleusercontent.com',
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("googleButton"),
        { theme: "outline", size: "large", width: 100, }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    };
  }

  async handleCredentialResponse(response: CredentialResponse) {
    this.googleData = JSON.parse(atob(response.credential.split('.')[1]))
    this.googleSignUP(this.googleData.email, this.googleData.given_name)
  }

  loginFormSubmit() {
    if (!this.loginForm.valid) {
      this.submitted = true
    } else {
      this.otpService.loginwithPassword(this.loginForm.value.phone, this.loginForm.value.password).subscribe((res: any) => {
        this.isApiReturned = true;
        if (res.error || res.response.status !== 200) {
          this.staffLogin()
          // this.loginForm.reset();
          // this.toastConfig = {};
          // this.toastConfig.error = true;
          // this.toastConfig.result = res.response.result;
        }
        if (res.response.status === 200) {
          localStorage.setItem('storeunqId', res.response.result.my_profile.recentstore)
          localStorage.setItem('userName', res.response.result.my_profile.name)
          localStorage.setItem('userId', res.response.result.my_profile.unqId)
          localStorage.setItem('userCategory', 'Admin')
          this.router.navigate(['/admin']);
          // this.router.navigate(['/guest/store/creating']);
        }
      })
    }
  }


  onlyNumberKey(event) {
    if (this.loginForm.value.phone)
      return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }

  googleSignUP(email: any, name: any) {
    this.otpService.googleSignIn(email, name).subscribe((res: any) => {
      if (res.status == 200) {
        localStorage.setItem('userId', res.unqId)
        this.router.navigate(['/admin']).then(() => window.location.reload());
      }
    })
  }

  async login() {
    FB.login(async (result: any) => {
      this.loginService.getFacebookData(result.authResponse.userID, result.authResponse.accessToken).subscribe((res) => {
        this.fbData = res
        this.facebookSignUP(this.fbData)
      })
    }, { scope: 'email' });
  }

  facebookSignUP(data) {
    this.otpService.facebookSignIn(data.email, data.name).subscribe((res: any) => {
      if (res.status == 200) {
        localStorage.setItem('userId', res.unqId)
        this.router.navigate(['/admin']).then(() => window.location.reload());
      }
    })
  }

  goToSignin() {
    this.router.navigateByUrl('guest/signup').then(() => window.location.reload());
  }
  showpassword = false;
  toggleShow() {
    this.showpassword = !this.showpassword
  }


  staffLogin() {
    this.adduserService.loginWithUser(this.loginForm.value.phone, this.loginForm.value.password).subscribe((res: any) => {
      if (res.status == 400) {
        // this.copyText('User not registered')
        this.loginFormSubmit()
      } else if (res.status == 420) {
        this.copyText(res.message)
        // this.loginFormSubmit()
      } else {
        this.headerService.getPreviousStore(res.data[0].userId || res.data[0].unqId, res.data[0].storeId || 'vendor').subscribe((res: any) => {
        })
        if (res.data[0].userCategory) {
          localStorage.setItem('userRole', res.data[0].role)
          localStorage.setItem('userstoreId', res.data[0].storeId)
          localStorage.setItem('userCategory', res.data[0].userCategory)
          localStorage.setItem('storeunqId', res.data[0].storeId)
          localStorage.setItem('userRole', res.data[0].role)
          localStorage.setItem('userId', res.data[0].unqId)
          localStorage.setItem('userName', res.data[0].name)
          this.router.navigate(['/admin'])
        } else {
          localStorage.setItem('userRole', res.data[0].role)
          localStorage.setItem('userstoreId', res.data[0].storeId)
          localStorage.setItem('userCategory', res.data[0].role)
          localStorage.setItem('storeunqId', res.data[0].storeId)
          localStorage.setItem('userRole', res.data[0].role)
          localStorage.setItem('userId', res.data[0].userId)
          localStorage.setItem('userName', res.data[0].name)
          this.otpFormSubmit(res.data[0].userId || res.data[0].unqId)
        }

      }
    })
  }

  otpFormSubmit(user_id) {
    let obj = {
      "password": "",
      "otp": '5090',
      "user": user_id,
      "method": "signup",
      "storeId": "dashboard",
      "role": "SO"
    }
    this.otpService.otpVerify(obj).subscribe((res: any) => {
      this.isApiReturned = true;
      if (res.error || res.response.status !== 200) {
        this.copyText('Something went wrong')
      }
      if (res.response.status === 200) {
        this.router.navigate(['/admin'])
      }
    })
  }


  copyText(data: any) {
    this.toastr.warning(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

}
