import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreDatasService {

  public storeDetails$ = new Subject();
  public storeList$ = new Subject();
  public sideNav$ = new BehaviorSubject<any>(true);
  public storeMenuList$ = new BehaviorSubject<any>(true);
  public storePreviewScreen$ = new BehaviorSubject<any>(false);
  public showWarning$ = new BehaviorSubject<any>(false);
  public showCard = new BehaviorSubject<any>(false);
  public hideOrderStatus = new BehaviorSubject<any>(false);
  public selectedPlan= new BehaviorSubject<any>(null);
  public logoChange= new BehaviorSubject<any>(false);
  public logout= new BehaviorSubject<any>(false)
  public responsive= new BehaviorSubject<any>(false)
  public orderDteails= new BehaviorSubject<any>(null)
  public type= new BehaviorSubject<any>(null)
  public menuToggle = new BehaviorSubject<any>(true)
  public alertSystem = new BehaviorSubject<any>(true)
  public activeTabIndex = new BehaviorSubject<any>(0)
  public item = new BehaviorSubject<any>(null)
  public activeSubTabIndex = new BehaviorSubject<any>(0)

  constructor() {}

  public setStoreDetails(data: any) {
    this.storeDetails$.next(data);
  }

  public removeStoreList(data: string) {
    this.storeList$.next(data);
  }

  private store:any = {
    selectedPlan: new BehaviorSubject(null)
  };

  public getStore(key:any): Observable<any> {
    return this.store[`${key}`];
  }

  public setStore(key:any, value:any): void {
    this.store[`${key}`].next(value);
  }

  public setMenuToggle(data: any) {
    this.menuToggle.next(data);
  }

  public removeMenuToggle(data: string) {
    this.menuToggle.next(data);
  }
  
  public setalertSystem(data: any) {
    this.alertSystem.next(data);
  }

  public removealertSystem(data: string) {
    this.alertSystem.next(data);
  }
}
