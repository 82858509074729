import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResetPasswordService } from '../../modules/admin/services/reset-password.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public isApiReturned: boolean;
  public forgotForm: FormGroup;
  public toastConfig: any;
  public submitted: any = false

  constructor(
    private formBuilder: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private toastr: ToastrService,
  ) {

    this.forgotForm = this.formBuilder.group(
      {
        email_id: ['', Validators.required],
      }
    )

  }
  ngOnInit(): void {
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }

  sendEmail() {
    if (!this.forgotForm.valid) {
      this.submitted = true
    } else {
      let data = {
        email: this.forgotForm.value.email_id
      }
      this.resetPasswordService.forgotPassword(data).subscribe(
        // (res: any) => {
        //   this.isApiReturned = true;
        //   if (res.response.status == 200) {
        //     this.forgotForm.reset();
        //     this.toastConfig = res.response.result;
        //   } else {
        //     this.toastConfig = res.response.result;
        //   }
        // }
        (res:any)=>{
          if (res.accepted.length > 0 && res.rejected.length === 0) {
            this.copyTextSuccess('Email successfully sent to '+ res.accepted)
        } else {
            this.copyTextError('Failed to send email to '+ res.rejected)
        }
        },
        (error:HttpErrorResponse)=>{
          this.copyTextError(error.error.message)
        }
      )
    }

  }

  goToLogin() {
    this.router.navigate(['/guest']).then(() => window.location.reload());
  }

  copyTextError(data: any) {
    this.toastr.error(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

  copyTextSuccess(data: any) {
    this.toastr.success(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });

  }
}
