import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { SmallCardComponent } from './small-card/small-card.component';
import { ModalComponent } from './modal/modal.component';
import { FormComponent } from './form/form.component';
import { ReactiveFormsModule , FormsModule} from '@angular/forms';
import { DealTableComponent } from './deal-table/deal-table.component';
import { ToastComponent } from './toast/toast.component';
import{RouterModule} from '@angular/router';
import { PaginationComponent } from './pagination/pagination.component';
import { TableContentCheckComponent } from './table-content-check/table-content-check.component';
import { ChartsModule } from 'ng2-charts';
import { ChartComponent } from './chart/chart.component';

@NgModule({
  declarations: [TableComponent, ContentHeaderComponent, SmallCardComponent, ModalComponent, FormComponent, DealTableComponent,ToastComponent, PaginationComponent, TableContentCheckComponent, ChartComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule
  ],
  exports: [ContentHeaderComponent,SmallCardComponent,TableComponent,ModalComponent,FormComponent,DealTableComponent,ToastComponent,PaginationComponent,TableContentCheckComponent],
  bootstrap: [ModalComponent,],
})
export class ComponentsModule { }
