import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreDatasService } from 'src/app/store-datas.service';
import { CreateNewStoreService } from '../../modules/admin/services/create-new-store.service';
import { WindowRefService } from '../service/window-ref.service'
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CatalogService } from 'src/app/modules/admin/services/catalog.service';

@Component({
  selector: 'app-create-store',
  templateUrl: './create-store.component.html',
  styleUrls: ['./create-store.component.scss']
})
export class CreateStoreComponent implements OnInit {

  public StoreID: any = localStorage.getItem('userId');
  public cancelCheck= localStorage.getItem('otpPage')
  public createStoreForm: FormGroup;
  public alertStoreData: any;
  public isTrial: boolean = false;
  planAmount: any;
  planType: string;
  planDuration: string;
  razorPayKey: any;
  public today = new Date()
  public status: any;
  public result: any;
  public toastConfig: any;
  public isApiReturned: boolean;
  public alterNumberCheck: boolean = false;
  public disableButton: boolean;
  public countries = []
  public onSearch: any;
  public isShow = false;
  public createStore = true;
  public storeType = false;
  public plan = false;
  public confirm = false;
  public editStore = false;
  public editPlan = false;
  public storeTypeForm: FormGroup;
  public countrySelected = true
  public selectPlan = ""
  public storeTypeTitle: string= 'Instant Delivery';
  public storeTypeName: string;
  public storeTypeData: any[];
  typeSelected: boolean;
  sType: any;
  isStoreEdit: any;
  currentTab: string= "CURRENT"
  priorDate: any;
  expiryDate: string;
  public file: string[] = [];
  public files = [];
  @ViewChild('uploadStoreLogo', { static: false }) uploadStoreLogo: ElementRef;
  imagesOnboard: any;
  unSanitizedUrlCategory: any;
  storeLogo: any;
  planData: any;
  halfyearlyData: any= [];
  yearlyData: any= []
  selectedData: boolean;
  showCountryWarning: boolean= false;

  constructor(private formBuilder: FormBuilder, private createNewStore: CreateNewStoreService,
    private router: Router, private storeDetail: StoreDatasService, private http: HttpClient, private winRef: WindowRefService,
    private zone: NgZone, private toastr: ToastrService,private catalogService: CatalogService) {
    this.storeDetail.sideNav$.next(false);
    this.createStoreForm = this.formBuilder.group({
      name: ['', [Validators.required]], //Validators.pattern("^[a-zA-Z]+$")
      email: ['', [Validators.required, Validators.pattern("^[a-z]+([.-]?[a-z0-9]+)*@([a-z]+([.-]?[a-z]))[.]{1}[a-z]{2,}$")]],
      address: ['', Validators.required],
      phone1: ['', [Validators.required, Validators.pattern("[0-9]+")]], //Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")
      phone2: ['', Validators.pattern("[0-9]+")], //Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")
      pincode: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      city: ['', Validators.required],
      country: ['', Validators.required],
      location: ['', Validators.required],
    });

    this.storeTypeForm = this.formBuilder.group({
      type_category: ['Instant Delivery', Validators.required],
      type: ['', Validators.required]
    });

    this.storeTypeData = [
      {value: 'Instant Delivery', label: 'Instant Delivery'},
      {value: 'Logistics', label: 'Logistics'}
    ]
  }

  ngOnInit() {
    this.storeDetail.sideNav$.next(false);
    this.createStore = true;
    this.storeType = false;
    this.plan = false;
    this.confirm = false;
    this.editStore = false;
    this.editPlan = false;
    // this.planDetails()
  }

  // planDetails() {
  //   this.createNewStore.planDetails().subscribe((res: any)=> {
  //     this.planData = res.data
  //     this.planData.forEach(element => {
  //       if(element.data.type == 'Halfyearly') {
  //        this.halfyearlyData.push(element)
  //       } else {
  //         this.yearlyData.push(element)
  //       }
  //     });
  //   })
  // }



  payWithRazor() {
    const options: any = {

      key: 'rzp_live_y2fRo4W0C5Wmoc',
      amount: this.planAmount * 100, 
      currency: 'INR',
      name: this.planDuration, 
      image: 'assets/images/MicrosoftTeams-image.png',
      method: {
        netbanking: true,
        card: true,
        upi: true,
        wallet: false,
      },
      modal: {
        escape: false,
      },
      notes: {
      },
      theme: {
        color: '#5568FE',
      },
      handler: (response, error) => {
        if (response) {
          this.paymentSuccess(response);
        } else if (error) {
        }
      },
    };
    options.modal.ondismiss = () => {
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  paymentSuccess(response) {
    localStorage.removeItem('storeunqId')
    
    this.createNewStore.createNewStore(this.createStoreForm.value, this.StoreID, this.storeTypeForm.value, this.selectPlan,this.isTrial, this.priorDate, this.storeLogo).subscribe((res: any) => {
      this.isApiReturned = true;
      if (res.response.status == 500) {
        this.alertStoreData = res.response.result;
        this.toastConfig = {};
        this.toastConfig.error = true;
        this.createStoreForm.reset();
        this.toastConfig.result = res.response.result;
      } else
        if (!res.error && res.response.status == 200) {
          this.alertStoreData = res.response.result;
          this.createStoreForm.reset();
          this.toastConfig = {};
          this.toastConfig.error = true;
          this.toastConfig.result = res.response.result;
          localStorage.setItem('storeunqId', res.response.result.storeid);
          localStorage.setItem('enableLoader', 'true');
          this.createNewStore.addStoreSubDomain(res.response.result.storeid).subscribe((res: any) => {
          })
          this.zone.run(() => {
            this.router.navigateByUrl('/guest/store/creating');
          });
          this.isTrial = false;
        }
    })
  }

  onStoreTypeChange(event) {
    this.typeSelected = true
    this.storeTypeTitle = event.target.value;
  }

  getButtonValue(value: string, type: string) {
    this.storeTypeName = value;
    this.storeTypeForm.setValue({
      type: value,
      type_category: type
    });
  }

  getCountryList(data) {
    this.createNewStore.getAllCountries(data).subscribe((res: any) => {
      this.countries = res.response.result
    })
  }

  createStoreFormSubmit() {

    if(this.selectedData == true) {
      this.showCountryWarning= false
      this.enterStoreName(this.createStoreForm.value.name, 'new')
    } else {
      this.showCountryWarning= true
    }
  }

  editFormSubmit(){
    this.enterStoreName(this.createStoreForm.value.name, 'edit')
  }

  enterStoreName(name, data) {
   this.createNewStore.storeName(name).subscribe((res: any)=> {
    if(data == 'new') {
      if(res.message == "Add new") {
        this.createStore = false;
        this.storeType = true;
      } else {
        this.copyTextwar()
      }
    } else {
      if(res.message == "Add new") {
        if(this.sType == 1) {
      this.storeType= true
      this.createStore = false;
      this.plan = false;
      this.confirm = false;
    } else {
      this.createStore = false;
      this.confirm = true;
    }
      } else {
        this.copyTextwar()
      }
    }

   })
  }
    copyTextwar() {
      this.toastr.warning('Sorry, the store name is already taken. Please choose a different name for your store', '', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
    }

  editTypeSubmit(){
    this.storeType = false;
    this.confirm = true;
  }

  changeSearch(event) {
    this.countrySelected = true
    this.onSearch = event.target.value;
    if (this.onSearch.length > 2) {
      this.getCountryList(this.onSearch);
    } else {
      this.countries = []
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  numberCheck() {

    if (this.createStoreForm.controls['phone2'].value == this.createStoreForm.controls['phone1'].value) {
      this.alterNumberCheck = true;
      this.disableButton = false
    } else {
      this.alterNumberCheck = false;
      this.disableButton = true
    }

  }
  public freeViewMore: boolean = true;
  public deluxeViewMore: boolean = true;
  public primeViewMore: boolean = true;
  public enterpriseViewMore: boolean = true;
  public fullFree = ["Orders : Upto 100/M", "Categories", "Order Tracking", "COD", "Assign delivery assistant", "Catalogue Creation", "Offer Banners"]
  public fullDeluxe = ["Orders : Unlimited", "Categories : Unlimted", "Online Payment", "Order Tracking", "COD", "Delivery Assistant App", "Assign delivery assistant", "Inventory", "Catalogue Creation", "Offer Banners", "Coupon Codes"]
  public fullPrime = ["Orders : Unlimited", "Categories : Unlimted", "Online Payment", "Order Tracking", "COD", "Delivery Assistant App", "Assign delivery assistant", "Inventory", "Logistics", "Catalogue Creation", "Offer Banners", "Coupon Codes"]
  public fullEnterprise = ["Orders : Unlimited", "Categories : Unlimted", "Multi Vendor", "Online Payment", "Order Tracking", "COD", "Delivery Assistant App", "Assign delivery assistant", "Inventory", "Logistics", "Catalogue Creation", "Offer Banners", "Coupon Codes"]

  public free = this.fullFree.slice(0, 4);
  public deluxe = this.fullDeluxe.slice(0, 4);
  public prime = this.fullPrime.slice(0, 4);
  public enterprise = this.fullEnterprise.slice(0, 4);


  showAllFeatures(value: any) {
    if (value == 'free') {
      this.free = this.fullFree;
      this.freeViewMore = false;
    }
    else if (value == 'deluxe') {
      this.deluxe = this.fullDeluxe;
      this.deluxeViewMore = false;
    }
    else if (value == 'prime') {
      this.prime = this.fullPrime;
      this.primeViewMore = false;
    }
    else if (value == 'enterprise') {
      this.enterprise = this.fullEnterprise;
      this.enterpriseViewMore = false;
    }
  }
  hideAllFeatures(value: any) {
    if (value == 'free') {
      this.free = this.fullFree.slice(0, 4);
      this.freeViewMore = true;
    }
    else if (value == 'deluxe') {
      this.deluxe = this.fullFree.slice(0, 4);
      this.deluxeViewMore = true;
    }
    else if (value == 'prime') {
      this.prime = this.fullFree.slice(0, 4);
      this.primeViewMore = true;
    }
    else if (value == 'enterprise') {
      this.enterprise = this.fullFree.slice(0, 4);
      this.enterpriseViewMore = true;
    }
    // this.viewMore = true;
  }

  selectCountry(data) {
    this.selectedData= true
    this.createStoreForm.controls['country'].setValue(data);
    this.countrySelected = false
  }

  showDetails() {
    this.isShow = true
  }
isShowCon(){
  this.isShow=true
}
  cancel() {
    this.isStoreEdit= false
    this.confirm= false;
    this.plan= true
    this.storeType= false;
    this.createStore= false;
  }

  cancelBuild() {
    if(this.isStoreEdit == true) {
      this.createStore= false;
      this.confirm= true;
    } else {
      if(this.cancelCheck == '1'){
        this.router.navigate(['/admin'])
      } else {
        
        // this.router.navigate(['/guest/signup'])
      }
    }
  }

  selectStoreType() {
    this.plan = true
    this.storeType = false;
  }

  edit() {
    this.isStoreEdit = true;
      this.sType= 1
      this.editStore = true;
      this.createStore = true;
      this.storeType = false;
      this.plan = false;
      this.confirm = false;
  }

  back(destination:any) {

    if(destination == 'createStore'){
      this.sType= 1
      this.editStore = true;
      this.createStore = true;
      this.storeType = false;
      this.plan = false;
      this.confirm = false;
    }
    else if(destination == 'storeType'){
      this.storeTypeForm.controls['type'].patchValue(this.storeTypeForm.value.type)
      this.storeTypeForm.controls['type_category'].patchValue(this.storeTypeForm.value.type_category)
      this.editPlan = true;
      this.storeType = true;
      this.createStore = false;
      this.plan = false;
      this.confirm = false;
    }
    else if(destination == 'plan'){
      this.plan = true;
      this.storeType = false;
      this.createStore = false;
      this.confirm = false;
    }
    else if(destination == 'confirm'){
      this.confirm = true;
      this.storeType = false;
      this.createStore = false;
      this.plan = false;
    }
  }

  onClickOurPlan() {
    this.sType= 0
    this.storeType= true;
    this.plan= false;
    this.createStore= false;
    this.confirm= false;
  }

  freePlan(duration) {
    this.isTrial= true
    localStorage.setItem('trial', 'false')
    if(duration === 'Halfyearly') {
      this.selectPlan = "free";
      this.planAmount = 0;
      this.planDuration = duration;
      this.loadConfirmationPage();
    } else {
      this.selectPlan = "free";
      this.planAmount = 0;
      this.planDuration = duration;
      this.loadConfirmationPage();
    }
  }

  basicPlan(duration) {
    this.isTrial= false
    localStorage.setItem('trial', 'false')
    if(duration === 'Halfyearly') {
      this.priorDate = moment().add(6, 'M').format('DD-MM-YYYY');
      this.selectPlan = "basic";
      this.planAmount = 5999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    } else {
      this.priorDate = moment().add(12, 'M').format('DD-MM-YYYY');
      this.selectPlan = "basic";
      this.planAmount = 9999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    }
  }

  deluxePlan(duration) {
    localStorage.setItem('trial', 'false')
    this.isTrial= false
    if(duration === 'Halfyearly') {
      this.priorDate = moment().add(6, 'M').format('DD-MM-YYYY');
      this.selectPlan = "startup";
      this.planAmount = 11999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    } else {
      this.priorDate = moment().add(12, 'M').format('DD-MM-YYYY');
      this.selectPlan = "startup";
      this.planAmount = 19999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    }
  }

  primePlan(duration) {
    this.isTrial= false
    localStorage.setItem('trial', 'false')
    if(duration === 'Halfyearly') {
      this.priorDate = moment().add(6, 'M').format('DD-MM-YYYY');
      this.selectPlan = "enterprise";
      this.planAmount = 17999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    } else {
      this.priorDate = moment().add(12, 'M').format('DD-MM-YYYY');
      this.selectPlan = "enterprise";
      this.planAmount = 29999;
      this.planDuration = duration;
      this.razorKey()
      this.loadConfirmationPage();
    }
  }

  enterprisePlan() {
    this.selectPlan = "enterprise"
    this.loadConfirmationPage();
  }

  loadConfirmationPage(){
    this.plan = false;
    this.confirm = true;
  }

  createStoreDatas() {
    this.payWithRazor()
  }

  razorKey() {
    this.createNewStore.razorpayKey().subscribe((res: any)=> {
      this.razorPayKey= res.response.result.keyId
    })
  }

  freeTrial(planName) {
    this.isTrial = true
    localStorage.setItem('trial', 'true')
    this.priorDate = moment().add(14, 'days').format('DD-MM-YYYY');
    this.selectPlan = planName;
    this.planAmount = 0;
    this.planDuration = 'freeTrial';
    this.loadConfirmationPage();
  }

  uploadLogo(event) {
    this.file.push(event.target.files[0]);
    const fileInputOnboard = this.uploadStoreLogo.nativeElement;
    for (const file of fileInputOnboard.files) {
      this.files.push({ data: file, inProgress: false, progress: 0 });
    }

    let fr = new FileReader();
    var _URL = window.URL || window.webkitURL;

    fr.onload = () => {
    }
    var img = new Image();
    this.isApiReturned = true;
    img.onload = () => {
       if((img.width == 512 && img.height == 512 )) {
        this.fileUploadOnboard();
       } else {
        this.copyTextwarlogo("The required image size is not suitable.The image size should be 512 x 512.")
       }
    }
    img.src = _URL.createObjectURL(event.target.files[0]);
  }

  copyTextwarlogo(msg) {
    this.toastr.warning(msg, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

  fileUploadOnboard() {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('file', file.data);
    }
    formData.append('dir_name', 'storeLogo')
    this.catalogService.fileEdit(formData).subscribe((res: any) => {
      this.isApiReturned = true;
      if (res.Success.status == 200) {
        this.toastConfig = res.Success;
        this.storeLogo = res.Success.url[0];
        this.unSanitizedUrlCategory = res.Success.url[0];
      } else if (res.error) {
        this.toastConfig = res.Success;
      }
    })
  }
}
