<app-toast style="z-index: 1;" [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>

<div class="d-flex justify-content-between">

</div>

<div class="col-lg-7 mx-auto loginContent">
  <!-- Basic login form-->
  <h2 style="margin-top: 60px;">Get Started</h2>
  <div style="z-index: 99;">
    Welcome to Dailystore, the user-friendly platform that makes it easy to turn your ideas into reality - sign up now and start creating your store app today!
  </div>
  <div style="text-align: center;">
    <img src="assets/images/PASSWORD RESET.png" style="width: 50%; margin-top: 0px;">
  </div>
</div>

<div class="col-lg-5 mx-auto" style="margin-top: 20px;" >
  <!-- Basic forgot password form-->
  <div class="card shadow-lg border-0 rounded-lg mt-5">
      <div class="card-header justify-content-center"><h3 class="tittle">Password Recovery</h3></div>
      <div class="card-body">
          <div class="resett">Enter your email address and we will send you a link to reset your password.</div>
          <!-- Forgot password form-->
          <form [formGroup]="resetPassword" >
              <!-- Form Group (Store Email)-->
              <div class="form-group">
                  <label class="small mb-1" for="inputUser">User</label>
                  <input class="form-control" id="inputUser" type="user"
                      formControlName="user" disabled/>
              </div>
              <!-- Form Group (password) -->
              <div class="form-group">
                  <label class="small mb-1" for="inputPassword">Password <svg (click)="showDialog()" xmlns="http://www.w3.org/2000/svg"
                    style="margin-top: -3px; cursor: pointer;" width="16" height="16" fill="currentColor"
                    class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg></label>
                <div *ngIf="categoryShowDialog" class="category-dialog">
                  <div class="arrow">
                      <div class="outer"></div>
                      <div class="inner"></div>
                  </div>
                  <div class="message-body">
                      <p>
                        Passwords must have upper and lower case letters, at least 1 number
                        and special character, and atleast 8 characters long.
                      </p>
                  </div>
              </div>
                  <input class="form-control" id="inputPassword" type="password"
                      formControlName="password" />
                      <span *ngIf="resetPassword.get('password').hasError('required') && resetPassword.get('password').touched"
                      class='error-message text-danger'>Password is required</span>
                      <span *ngIf="resetPassword.get('password').hasError('pattern') && resetPassword.get('password').touched"
                      class='error-message text-danger'>Enter the valid password</span><br>
              </div>
              <!-- Form Group (confirm password) -->
              <div class="form-group">
                  <label class="small mb-1" for="inputconfirmPassword">Confirm Password</label>
                  <input class="form-control" id="inputconfirmPassword" type="password"
                       formControlName="confirm_password" />
                       <span *ngIf="resetPassword.get('confirm_password').hasError('Mustmatch') && resetPassword.get('confirm_password').touched"
                      class='error-message text-danger'>Password and confirm password should be same</span>
              </div>

              <!-- Form Group (submit options)-->
              <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                  <a class="small" href="" routerLink="/guest">Return to login</a>
                  <button class="btn"  (click)="onClickReset()">Reset Password</button>
              </div>
          </form>
      </div>
  </div>
</div>


