<nav class="topnav navbar navbar-expand new-shadow navbar-light bg-white" id="sidenavAccordion">
  <div class="d-flex align-items-center">
    <a class="navbar-brand d-flex align-items-center" [ngStyle]="{'min-width': isSidenavOpen ? '15rem' : '0'}">
      <img *ngIf="selectedStore?.logo" rel="icon" [src]="selectedStore?.logo" class="desktop-image">
      <div class="logo-placeholder d-flex align-items-center justify-content-center mr-2 "> <!-- font-effect-fire -->
        {{logoPlaceholder}}
      </div>
     
      <h5 *ngIf="isSidenavOpen" class="mb-0 mr-2 ">{{selectedStore?.name}}</h5>
    </a>
    <button class="d-none d-md-block btn btn-light" (click)="toggleSidenav();">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
      </svg>
    </button>
    <button class="d-md-none btn btn-light" (click)="toggleSidenav();">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
      </svg>
    </button>

    <h2 style="font-size: 22px;">{{currentPageName}}</h2>
  </div>



  <ul class="navbar-nav align-items-center ml-auto">
    <li *ngIf="userType != 'vendor'">
      <button class="btn btn-light mr-3" (click)="openNav()"  data-toggle="tooltip" data-placement="bottom" title="Mobile Preview"> 
        <img src="assets/images/Smartphone.svg">
      </button>
    </li>
    <li *ngIf="userType != 'vendor'" class="mr-3" style="cursor: pointer;" >
      <button class="btn btn-light mr-3" id="navbarDropdownUserImage" data-toggle="tooltip"
      title="Web Preview" href="#webPreview" role="button" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false" > 
      <img src="assets/images/Laptop Minimalistic.svg">
    </button>
    </li>
    <li class="divider">
      <img src="assets/icons/divider.svg" alt="">
    </li>


    <li class="d-flex align-items-center" id="navbarDropdownUserImage" data-toggle="tooltip" title="Logout"
      href="#logout" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div style="
      width: 40px;
      height: 40px;
      border-radius: 50px;
      text-align: center;
      align-items: center;
      display: flex;
      padding-left: 13px;
      z-index: 9999999999;
      margin-left: 13px;
      color: white;
      background-color: #EFF2F5;
      font-weight: 500; cursor: pointer;" >
        <img [attr.src]="currentLogo" style="height: 35px; margin-left: -10px;padding: 5px;">
      </div>
      <div class="d-flex flex-column">
        <div class="name">{{StoreName | titlecase}}</div>
        <div class="name1">{{userType}}</div>
      </div>
    </li>


    <li class="nav-item dropdown no-caret mr-2 dropdown-user" *ngIf="userType != 'vendor'">
      <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" id="logout"
        aria-labelledby="navbarDropdownUserImage" style="margin-top: 20px; width: 350px;"
        (click)="$event.stopPropagation()">
        <h6 class="dropdown-header d-flex align-items-center">
          <div class="dropdown-user-details" style="width: 100%">
            <div style="font-size: 14px; color: #252525; font-weight: 500;margin-bottom: -10px;">Switch Store</div>
            <hr style="width: 300px">
            <div style="height: 70px; overflow: auto; margin-top: 20px;">

              <div class="d-flex justify-content-between" style="padding-bottom: 10px;"
                *ngFor="let store of storedetails">
                <div class="d-flex justify-content-start" style="cursor: pointer;"
                  (click)="getStoreId(store.unqId, store.name)">
                  <div class="storeFirstName">
                    {{store?.name[0] | uppercase}}
                  </div>
                  <div class="storeName">
                    {{store.name}}
                  </div>
                </div>
                <div *ngIf="this.StoreID === store.unqId" style="padding-right: 10px;">
                  <img style="margin-top: 8px;" src="assets/images/TIK.svg">
                </div>
              </div>
            </div>
            <div class="dropdown-user-details-email pt-2" style="cursor: pointer;">
              <div class="mr-3" style="height: 28px; margin-top: 5px; margin-bottom: -10px; font-size: 14px;"
                (click)="createStore()"> <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;" width="15"
                  height="15" fill="green" class="bi bi-plus-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> &nbsp;New Store</div>
            </div>
          </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" type="button" (click)="logout()">
          <div class="dropdown-item-icon">
            <svg style="width: 18px; height: 18px; margin-top: 2px" xmlns="http://www.w3.org/2000/svg" width="14"
              height="14" fill="#1575E6" class="bi bi-power" viewBox="0 0 16 16">
              <path d="M7.5 1v7h1V1h-1z" />
              <path
                d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
            </svg>
          </div>
          Logout
        </a>
      </div>
    </li>

    <li class="nav-item dropdown no-caret mr-2 dropdown-user" *ngIf="userType != 'vendor'">
      <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" id="webPreview"
        aria-labelledby="navbarDropdownUserImage" style="margin-top: 20px; width: 350px;"
        (click)="$event.stopPropagation()">
        <h6 class="dropdown-header d-flex align-items-center">
          <div class="dropdown-user-details" style="width: 100%">
            <div class="d-flex justify-content-between">
              <div style="font-size: 14px; color: #252525; font-weight: 500;margin-bottom: -10px; margin-top: 10px;">Web
                store URL</div>
              <button class="btn" (click)="openBrowser()" data-toggle="tooltip" data-placement="bottom" title="View">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1575E6"
                  class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                  <path fill-rule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                </svg>
              </button>
            </div>

            <hr style="width: 300px">
            <form>
              <div style="position: relative;">
                <input type="text" [value]="webSiteURL" #userinput class="form-control"
                  style="padding-right: 55px; background-color: white;" readonly>
                <button class="btn" style="background-color: #e7e2e2eb;
                      position: absolute;
                      right: 2px;
                      top: 4px;" data-toggle="tooltip" data-placement="bottom" title="Copy"
                  (click)="copyInputMessage(userinput)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="#1575E6" class="bi bi-files" viewBox="0 0 16 16">
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                  </svg></button>
              </div>
            </form>
          </div>
        </h6>
      </div>
    </li>
  </ul>
</nav>
<div *ngIf="attendationAlert" class="alert alert-danger alert-dismissible fade show mb-o">
  <button type="button" class="close" (click)="hideAlert('dateAlert')">&times;</button>
  <strong>Attention!</strong> Your plan is expiring in {{expiryDays}}. Renew now to avoid service interruption
  <span><button *ngIf="currentPageName != 'Settings'" class="btn btn-danger"
      (click)="goToSubscription()">Upgrade</button></span>
</div>

<div *ngIf="showExpiredMsg" class="alert alert-danger alert-dismissible fade show mb-0">
  <button type="button" class="close" (click)="hideAlert('expired')">&times;</button>
  <strong>Attention!</strong> Your plan is expired. You only have 30 days left to take action and update the status of
  your orders.
  <span><button class="btn btn-danger" *ngIf="currentPageName != 'Settings'"
      (click)="goToSubscription()">Upgrade</button></span>
</div>


<!--Logout  Modal -->

<div class="modal backdrop overlay" role="dialog" [ngStyle]="{'display':logoutPrombt}" #closeModal>
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content "
      style=" margin-left: 5%; margin-right: 5%; border-radius: 16px; border: 1px solid rgba(255, 255, 255, 0.30); background: #FFF; ">
      <div class="modal-body modal-scroll mx-auto text-center  areyou"
        style="color: #000; font-family: Inter; font-size: 24px; font-style: normal; font-weight: 600; line-height: 100%; margin-top: 3%;">
        <h2 style="font-family: Poppins;">Are you sure?</h2><br>

        <h5>Do you really want to logout from this site ? If yes click button "yes".</h5><br>
        <form>
          <div class="form-group mt-4">
            <div class="row justify-content-center">


              <button class="btn btn-success mr-4 bbn" type="button"
                style="width: 96px; height: 46px; color: #17171B !important; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 600; border-radius: 8px; border: 1px solid #BDBDC6; background: #fff !important;"
                (click)="closeModalDialog()">No</button>
              <button class="btn btn-danger bbn " type="submit"
                style="border-radius: 8px; background: #E00 !important; width: 96px; height: 46px; color: #fff; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 600;"
                (click)="logoutStore()">Yes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- Preview Screen Drawer -->

<div id="mySidenav" class="sidenav" style="margin-top: 60px; color: transparent; z-index: 1000000;">

  <div class="justify-content-center" style="margin-left: 27px;">
    <img src="assets/images/Group 2807.png" style="margin-top: -102px; margin-left: -34px; cursor: pointer;"
      (click)="closeNav()">
    <div style="margin-top: -40px;">
      <iframe [src]="iframeUrl" width="350" height="580" style="border: 1px solidblack; border-radius: 15px;"></iframe>
    </div>

  </div>
</div>

<!-- store change -->
<div class="modal backdrop overlay" tabindex="-1" role="dialog" [ngStyle]="{'display':storeChange}" #closeModal>
  <div class="modal-dialog displayModal" role="document">
    <div class="modal-content" style="max-width: 350px;">
      <div class="modal-body modal-scroll mx-auto">
        <h3 class="mt-4 text-center">Warning!</h3>
        <p class="text-center">Only admin can access to switch the store..</p>
        <form>
          <div class="form-group mt-4">
            <div class="row mb-2 pb-3 justify-content-center">
              <button class="btn btn-sm   btn-success" type="button" (click)="closeModalWarning()">OK</button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>