import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  public venderForm: FormGroup;

  constructor(private formBuilder: FormBuilder ,private router: Router) { 
    this.venderForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^[a-z]+([.-]?[a-z0-9]+)*@([a-z]+([.-]?[a-z]))[.]{1}[a-z]{2,}$")]],
      address: ['', Validators.required],
      phone1: ['', [Validators.required, Validators.pattern("[0-9]+")]], 
      store:['', [Validators.required, Validators.pattern('[A-Za-z0-9]{5,10}')]],
      gstin:['', [Validators.required, Validators.pattern('[0-9A-Za-z]{15}')]],
      street:['',Validators.required],
      country:['',Validators.required],
      state:['',Validators.required],
      city:['',Validators.required],
      pincode: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(6), Validators.minLength(6)]],

    })
  }

  ngOnInit(): void {
  }


  venderFormSubmit(){

  }
  cancelBuild(){
    
  }
  navigateToNextPage() {
  }
}


