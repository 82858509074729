import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Input() config: any;
  @Output() closed: any = new EventEmitter();
  @ViewChild('toastAlertClose') toastAlert: ElementRef<HTMLElement>;

  constructor() {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.toastAlert.nativeElement.click();
      this.closed.emit(true);
    }, 3000);
  }

  public alertDismissed(): void {
    this.closed.emit(true);
    this.config= '';
  }
}
