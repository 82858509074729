<div *ngIf="showloader" class="container">
    <div class="gif-container">
        <h3 class="text-center" style="margin-bottom: 20px; font-weight: 400;">Hi <span style="font-weight: 600;">{{userName}},</span></h3>
        <p style="margin-bottom: 40px;">Please Wait Your Store is creating</p>
        <img src="assets/images/creating-store.gif" alt="creating store">
    </div>
</div>

<div *ngIf="showSuccess" class="container">
    <div class="content-center text-center" style="margin-top: 20%;">
        <div class="x-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#73A942" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
        </div>
        <h3 style="margin-top: 20px">Store Created Successfully</h3>

    </div>
</div>
