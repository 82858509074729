import { Injectable } from '@angular/core';
import { Observable , of } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiBaseUrl = `${environment.apiUrl}`;
  private apiNodeBaseUrl = `${environment.apiUrlNode}`;

  constructor(private http: HttpClient) { }

  // public loginCheck(data: any) {
  //   return this.http.post(`${this.apiBaseUrl}`,
  //   {
  //     name:'signIn',
  //     param: {
  //       mobile: data
  //     }
  //   });
  // }

  public loginCheck(data: any) {
    return this.http.post(`${this.apiNodeBaseUrl}auth/mobile-login`,data);
  }

  public loginwithPassword(data: any) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'loginWithPassword',
      param: {
        data: data
      }
    });
  }

  getFacebookData(userId, accessToken) {
    return this.http.get(`https://graph.facebook.com/${userId}?fields=id,name,email,picture&access_token=${accessToken}`, { headers: new HttpHeaders({
      'Authorization': '{data}',
      'Content-Type' : 'application/json'
    })})
  }
}
