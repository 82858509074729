<app-toast style="z-index: 1;" [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>



<div class="d-flex justify-content-between">
  <div class="col-lg-6 mx-auto loginContent">
    <!-- Basic login form-->
    <h2 style="margin-left: 50px; margin-top: 50px;" class="card-heading"></h2>
    <div class="description" style="margin-left: 60px; margin-right: 45px;">
      Forgot your Dailystore password? No worries-just enter your email address and we'll send you instructions to reset
      it!
    </div>
    <div style="text-align: center; margin-top: 55px;">
      <img src="assets/images/Reset_password.png" style="width: 60%;">
    </div>
  </div>



  <div class="col-lg-6 mx-auto coloum-2" style="margin-top: -90px;
   background: #FFF;
  min-height: 100vh;
  -webkit-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
-moz-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
">
    <div style="text-align: center; margin-top: 146px;" class="green_logo"><img src="assets/images/green_logo.png"></div>
    <!-- Basic forgot password form-->
    <div  class="newstyle">
      <div class="col-2-section">
        <div class="col-2-heading">Password Recovery</div>
        <div class="card-body">
          <div class="textt" >Enter your email address and we will send you a link to
            reset your password.</div>
            
          <!-- Forgot password form-->
          <form style="margin-top: 24px;"  [formGroup]="forgotForm">
            <!-- Form Group (email address)-->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label style="color: black; font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                " for="inputEmailAddress">Email address</mat-label>
                <input matInput formControlName="email_id" id="inputEmailAddress" type="email"
                  aria-describedby="emailHelp" placeholder="Enter email address" required />
              </mat-form-field>
              <span *ngIf="forgotForm.get('email_id').hasError('required') && (forgotForm.get('email_id').touched || submitted)"
                class='error-message text-danger'>Email is required</span>
            </div>
            <!-- Form Group (submit options)-->
            <div class=" d-flex align-items-center justify-content-between mt-4 mb-0">
              <button class="btn container reset"
                (click)="sendEmail()">Reset Password</button>
            </div>
            <br>
            <div style="text-align: center;">
              <a class="small reset-pass" style="color: #4CAF50; cursor: pointer" (click)="goToLogin()">Return to login</a>
            </div>
  
          </form>
        </div>
      </div>
    </div>
    
  </div>
</div>
