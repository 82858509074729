import { Component, OnInit,Input } from '@angular/core';
import { AdminModule } from '../../modules/admin/admin.module';
import { DashboardComponent } from '../../modules/admin/dashboard/dashboard.component';
@Component({
  selector: 'app-small-card',
  templateUrl: './small-card.component.html',
  styleUrls: ['./small-card.component.scss']
})
export class SmallCardComponent implements OnInit {
  @Input() config: any;
  constructor() { }

  ngOnInit(): void {
  }

}
