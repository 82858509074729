import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-new',
  templateUrl: './sidenav-new.component.html',
  styleUrls: ['./sidenav-new.component.scss']
})
export class SidenavNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
