import { Component, HostListener, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-outer-nav',
  templateUrl: './outer-nav.component.html',
  styleUrls: ['./outer-nav.component.scss'],
})
export class OuterNavComponent {
  @ViewChild('drawer') drawer: any;
  appBuilderSelected: boolean = false;
  isAnalyticsNavItemOpen = false;

  count: number = 0;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 1500px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  userInfo: any;
  config: MatDialogConfig = {};
  SelectOrg: any;
  selectedOrgDetail: any;
  fired: boolean = false;
  selectedNavItem: any;
  isHandset: boolean;
  orgList: any;
  dropMenuState: boolean = false;
  roles = [];
  innerNavState: boolean;
  appList: any = [];
  apps: any = [];
  roleApp: any = [];
  routerLinkMenu: boolean = false;
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.dropMenuState = false;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private router: Router,
  ) {

  }
}
