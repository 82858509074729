import { Component, OnInit, Input, ElementRef, ViewChild, VERSION } from '@angular/core';
import { FormGroup,FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CatalogService } from '../../modules/admin/services/catalog.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input() config: any;
 
  public catalogForm: FormGroup;
  private storeId = localStorage.getItem('storeunqId')
  public StoreID: any;
  file: string [] = [];
  files  = [];
  image: any
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  public toastConfig: any;
  public isApiReturned: boolean;
  public imagePath;
  public displayCatalog ='none';
  @Input() data: any = 'block';
  imgURL: any;
  public message: string;
  public imageFile : any;
  public currentPage = 1;
  colors: FormArray;
  sizes: FormArray;
  categories: []
  catalogCount:any;
  title: any;
  constructor(
    private formBuilder: FormBuilder,
    public http: HttpClient,
    private catalogService : CatalogService,
    ) {
    this.catalogForm = this.formBuilder.group(
      {
        title: ['',Validators.required],
        description: ['',Validators.required],
        category: this.formBuilder.array([
          this.categories= []
        ]),
        price: ['',Validators.required,],
        offer_price: ['',Validators.required],
        file: [''],
        deal_item: [false],
        catalog_item:  [false],
        brand: ['', Validators.required],
        quantity: ['', Validators.required],
        tax: ['', Validators.required],
        colors: this.formBuilder.array([
          this.colorForm()
        ]),
        sizes: this.formBuilder.array([
          this.sizeForm()
        ]),
        storeId: [this.StoreID]
      }
    )
   }

   colorForm() {
    return this.formBuilder.group({
      color: [''],
      isAvailable: ['true']
    });
  }

   sizeForm() {
    return this.formBuilder.group({
      size: [''],
      isAvailable: ['true']
    });
  }

  getControls() {
    return (this.catalogForm.get('colors') as FormArray).controls;
  }

  public addColorButtonClick(): void {
    this.colors = this.catalogForm.get('colors') as FormArray;
    this.colors.push(this.colorForm());
  }

  public removeColorButtonClick(idx): void {
    (<FormArray>this.catalogForm.get('colors')).removeAt(idx);
  }

  getControl() {
    return (this.catalogForm.get('sizes') as FormArray).controls;
  }

  public addSizeButtonClick(): void {
    this.sizes = this.catalogForm.get('sizes') as FormArray;
    this.sizes.push(this.sizeForm());
  }

  public removeSizeButtonClick(idx): void {
    (<FormArray>this.catalogForm.get('sizes')).removeAt(idx);
  }

  ngOnInit(): void {
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }
  
  
  catalogFormSubmit(){
    this.StoreID = localStorage.getItem('storeunqId');
    this.catalogService.createCatalog(this.catalogForm.value, this.image, this.title, this.data).subscribe((res: any) => {
      this.isApiReturned = true;
      if (res.response.status === 200) {
        this.toastConfig = res;
        this.catalogForm.reset();
        this.closeCatalogEditDialog();
        }
      else 
      {
        this.toastConfig = res;
      }
    }, (error) => {
      this.toastConfig = {};
      this.toastConfig.result = 'Error created catalog please try again';
    });
  }

  fileUpload() {
   const formData = new FormData();
   for (const file of this.files) {
    formData.append('file', file.data);
  }
  formData.append('dir_name', 'catalogs/'+ this.storeId)
  this.catalogService.fileEdit(formData).subscribe((res: any)=> {
   this.image= res.Success.url[0];
  })
  }

  closeCatalogEditDialog()
  {
    this.displayCatalog = 'none';
  }


  onFileChanged(event): void {
    // this.file.push(event.target.files[0]);
    const fileInput = this.fileInput.nativeElement;
    for (const  file of fileInput.files) {
      this.files.push({ data: file, inProgress: false, progress: 0});
    }
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
   }
   
}
