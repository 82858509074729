import { Component, OnInit ,Input} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-table-content-check',
  templateUrl: './table-content-check.component.html',
  styleUrls: ['./table-content-check.component.scss']
})
export class TableContentCheckComponent implements OnInit {
  @Input() cell: any;
  @Input() config: any;
  @Input() row: any;
  @Input() DBoy : any;

  @Output() orderDetail = new EventEmitter<any>();
  @Output() userStatusChange = new EventEmitter<string>();
  @Output() userDetail = new EventEmitter<any>();
  @Output() selectDBoy = new EventEmitter<any>();
  @Output() statusChange = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  orderModal(row : any){
    this.orderDetail.emit(row);
  }
  changeUserStatus(row)
  {
  this.userStatusChange.emit(row);
  }
  openModal(value : string , row : any){
    this.statusChange.emit({value,row});
    //this.newItemEvent.emit(value);
  }
  userDetilModal(value : any){
    this.userDetail.emit(value);
  }
  selectBoy(value : string ,row : string)
  {
    this.selectDBoy.emit({value , row});
  }
}
