
            <div class="datatable">
                <table class="table table-hover" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th *ngIf="itemsPerPage">#</th>
                            <th *ngFor=" let e of config.coloumns">{{e.title}}</th>
                            <th *ngIf="config.actions" style="text-align: right;">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                            <tr *ngFor="let row of data; let i = index">
                                <td *ngIf="itemsPerPage">{{(itemsPerPage * (currentPage - 1)) + (i + 1)}}</td>
                                <!--Table Data-->
                                <td *ngFor="let cell of config.coloumns">
                                    <ng-container *ngIf="cell.type === 'images'">
                                        <img src="{{row[cell.field]}}" style="width: 30px; height: 30px; border-radius: 50px;"/>
                                    </ng-container>
                                    <ng-container *ngIf="cell.icon === 'rupee' && cell.type != 'images'">
                                        ₹ {{row[cell.field]}}
                                    </ng-container >
                                    <ng-container *ngIf=" cell.icon != 'rupee' && cell.type != 'images'">
                                        {{row[cell.field]}}
                                    </ng-container >

                                </td>
                                <!-- <td *ngIf="config.actions?.edit == true">
                                    <a href="#" class="edit-btn  mr-3" data-toggle="modal" data-target="{{config.actions.editModalName}}" (click)="editPrombt(row)">Edit</a>
                                    <a href="#" class="delete-btn " data-toggle="modal" data-target="{{config.actions.deleteModalName}}" (click)="deletePrombt(row)">Delete</a>
                                </td> -->
                                <td *ngIf="config.actions?.status == true" style="text-align: right;">


                                    <button class="btn delete-btn btn -info btn -sm ml-2" (click)="catalogStatusChange(row)" style="text-align: right;">Remove</button>
                                    <!-- <a href="#" class="edit-btn " data-toggle="modal" data-target="{{config.actions.editModalName}}" (click)="editPrombt(row)">Edit</a> -->
                                    <!-- <a href="#" class="delete-btn " data-toggle="modal" data-target="{{config.actions.deleteModalName}}" (click)="catalogStatusChange(row)">{{ row.status}}</a> -->
                                    <!-- <ng-container *ngIf="row.status === 'active'">
                                        <button class="btn btn-sm   btn -info btn -sm ml-2" (click)="catalogStatusChange(row)">Publish</button>
                                     </ng-container >
                                     <ng-container *ngIf="row.status === 'inactive'">
                                        <button class="btn btn-sm   btn -info btn -sm ml-2" (click)="catalogStatusChange(row)">Unpublish</button>
                                     </ng-container >  -->

                                </td>

                            </tr>
                    </tbody>
                </table>


            </div>




