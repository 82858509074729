import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SignupComponent } from './signup/signup.component';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ReactiveFormsModule , FormsModule} from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { CreateStoreComponent } from './create-store/create-store.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OtploginComponent } from './otplogin/otplogin.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { OuterNavComponent } from './outer-nav/outer-nav.component';
import { InnerNavComponent } from './inner-nav/inner-nav.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import { VendorComponent } from '../modules/admin/vendor/vendor.component';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidenavNewComponent } from './sidenav-new/sidenav-new.component';

@NgModule({
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ],
  declarations: [
    LoginComponent, 
    FooterComponent, 
    HeaderComponent, 
    SignupComponent, 
    ForgotPasswordComponent,
    SidenavComponent, 
    VerifyOTPComponent, 
    CreateStoreComponent, 
    ResetPasswordComponent, 
    OtploginComponent, 
    PreloaderComponent, 
    OuterNavComponent, 
    InnerNavComponent,
    VendorComponent,
    AddVendorComponent,
    SidenavNewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    NgbModule,
    MatListModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatFormFieldModule, MatInputModule, MatIconModule, MatMenuModule, MatExpansionModule, MatTooltipModule
  ],
  exports: [ HeaderComponent, FooterComponent,
    SignupComponent, LoginComponent,ForgotPasswordComponent,SidenavComponent,SidenavNewComponent],
})
export class SharedModule { }
