<footer class="footer mt-auto dashboard-footer">
    <div class="container-fluid" style="margin: 20px;">
        <div class="row">
            <div class="col-md-4 col-12 text-md-left d-flex align-items-center">
                <a style="color: white !important">Powered by : </a>
                <a style="color: white !important; margin-left: 5px;" href="https://www.moonhive.in/" target="_blank"> Moonhive</a>
            </div>
            <div class="col-md-8 col-12 d-flex justify-content-end align-items-center">
                <div >
                    <a class="m-2" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}" routerLink="t&c">
                        Privacy Policy
                    </a>
                    <a class="m-2" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}" routerLink="refundpolicy">
                        Refund Policy
                    </a>
                    <a class="m-2" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}" routerLink="cancelledpolicy">
                        Cancellation Policy
                    </a>
                    <a class="m-2" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}" routerLink="cookiepolicy">
                        Cookie Policy
                    </a>
                    <a class="m-2" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}" routerLink="miscellaneous">
                        Miscellaneous
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
