<nav class="topnav navbar navbar-expand new-shadow navbar-light bg-white" id="sidenavAccordion">
    <a class="navbar-brand">
      <img rel="icon" src="assets/images/logo (1).png" style="height: 33px; width: 127px;"><span
        class="pt-2"></span>
    </a>
  </nav>
  

  <div style="margin: 25px 25px 50px 25px !important;">
    <div class="row">
        <div div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
            <div class="card">
                <div *ngIf="vendorDetail" class="card-header">
                    <div class="store-details-card-header">
                        <div >
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="store-details-card-title card-title">
                                        <small class="v1">Vendor details</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <form [formGroup]="venderForm">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputStoreName"> Vendor Name<span style="color: red;">*</span></label>
                                            <input class="form-control form-control-sm " id="inputStoreName" type="text"
                                                formControlName="vendorName" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('vendorName').hasError('required') && (venderForm.get('vendorName').touched || submitted)"
                                                class='error-message text-danger'>Vendor name is required</span>
                                            <span
                                                *ngIf="venderForm.get('vendorName').hasError('pattern') && (venderForm.get('vendorName').touched || submitted)"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label for="inputPhoneNumber" class="small" for="inputPhoneNumber"
                                                    style="margin-bottom: 4px;">Phone
                                                    Number<span style="color: red;">*</span></label>
                                                <div>
                                                    <div class="inputicons">
                                                        <input formControlName="phone" type="text"
                                                            class="form-control form-control-sm phone"
                                                            id="inputPhoneNumber" required />
                                                        <span
                                                            *ngIf="venderForm.get('phone').hasError('required') && (venderForm.get('phone').touched || submitted)"
                                                            class='error-message text-danger'>Phone number is
                                                            required</span>
                                                        <span
                                                            *ngIf="venderForm.get('phone').hasError('pattern') && (venderForm.get('phone').touched || submitted)"
                                                            class='error-message text-danger'>Enter a valid 10-digit phone number</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputMailId">Email address<span style="color: red;">*</span></label>
                                        <input class="form-control form-control-sm " id="inputMailId" type="email"
                                            formControlName="email" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('email').hasError('required') && (venderForm.get('email').touched || submitted)"
                                            class='error-message text-danger'>E-mail is required</span>
                                        <span
                                            *ngIf="venderForm.get('email').hasError('pattern') && v(enderForm.get('email').touched || submitted)"
                                            class='error-message text-danger'>Enter the valid Email</span>
                                    </div>

                                </div>
                                <div class="card-header-underline1"></div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputRegNumber"> Store Reg Number<span style="color: red;">*</span></label>
                                            <input class="form-control form-control-sm " id="inputRegNumber" type="text"
                                                formControlName="regNumber" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('regNumber').hasError('required') && (venderForm.get('regNumber').touched || submitted)"
                                                class='error-message text-danger'>Store reg number is required</span>
                                            <span
                                                *ngIf="venderForm.get('regNumber').hasError('pattern') && (venderForm.get('regNumber').touched || submitted)"
                                                class='error-message text-danger'>Store reg number must be between 5 to 10 alphanumeric characters
                                               </span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label class="small" for="inputGstinNumber"> GSTIN Number<span style="color: red;">*</span></label>
                                                <input class="form-control form-control-sm " id="inputGstinNumber"
                                                    type="text" formControlName="gstin" style="margin-top: -4px;"
                                                    required />
                                                <span
                                                    *ngIf="venderForm.get('gstin').hasError('required') &&( venderForm.get('gstin').touched || submitted)"
                                                    class='error-message text-danger'>GSTIN number is required</span>
                                                <span
                                                    *ngIf="venderForm.get('gstin').hasError('pattern') && (venderForm.get('gstin').touched || submitted)"
                                                    class='error-message text-danger'>Enter a valid 15-character GSTIN number</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header-underline2"></div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputStreetAddress"> Street Address<span style="color: red;">*</span></label>
                                            <input class="form-control form-control-sm " id="inputStreetAddress" type="text"
                                                formControlName="streetAddress" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('streetAddress').hasError('required') && (venderForm.get('streetAddress').touched || submitted)"
                                                class='error-message text-danger'>Street address is required</span>
                                            <span
                                                *ngIf="venderForm.get('streetAddress').hasError('pattern') && (venderForm.get('streetAddress').touched || submitted)"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <div style="position: relative;">
                                                <label class="small" for="inputCountryName"> Country<span style="color: red;">*</span></label>
                                                <input class="form-control form-control-sm " id="inputCountryName"
                                                    type="text" formControlName="country" style="margin-top: -4px;"
                                                    required />
                                                <span
                                                    *ngIf="venderForm.get('country').hasError('required') && (venderForm.get('country').touched || submitted)"
                                                    class='error-message text-danger'> Country is required</span>
                                                <span
                                                    *ngIf="venderForm.get('country').hasError('pattern') && (venderForm.get('country').touched || submitted)"
                                                    class='error-message text-danger'>Special character and double space
                                                    are
                                                    not allowed</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5 col-md-3">
                                            <label class="small" for="inputStateName"> State<span style="color: red;">*</span></label>
                                            <input class="form-control form-control-sm " id="inputStateName" type="text"
                                                formControlName="state" style="margin-top: -4px;" required />
                                            <span
                                                *ngIf="venderForm.get('state').hasError('required') && (venderForm.get('state').touched || submitted)"
                                                class='error-message text-danger'>State is required</span>
                                            <span
                                                *ngIf="venderForm.get('state').hasError('pattern') && (venderForm.get('state').touched || submitted)"
                                                class='error-message text-danger'>Special character and double space are
                                                not allowed</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputCityName"> City<span style="color: red;">*</span></label>
                                        <input class="form-control form-control-sm " id="inputCityName" type="text"
                                            formControlName="city" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('city').hasError('required') && (venderForm.get('city').touched || submitted)"
                                            class='error-message text-danger'>City is required</span>
                                        <span
                                            *ngIf="venderForm.get('city').hasError('pattern') && (venderForm.get('city').touched || submitted)"
                                            class='error-message text-danger'>Special character and double space are
                                            not allowed</span>
                                    </div>
                                    <div class="col-sm-5 col-md-3">
                                        <label class="small" for="inputPincode"> Pincode<span style="color: red;">*</span></label>
                                        <input class="form-control form-control-sm " id="inputPincode" type="text"
                                            formControlName="pincode" style="margin-top: -4px;" required />
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('required') && (venderForm.get('pincode').touched || submitted)"
                                            class='error-message text-danger'>Pincode is required</span>
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('pattern') && (venderForm.get('pincode').touched || submitted)"
                                            class='error-message text-danger'>Special character and double space are
                                            not allowed</span>
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('maxLength') && (venderForm.get('pincode').touched || submitted)"
                                            class='error-message text-danger'>You can only enter 6 digit pincode</span>
                                        <span
                                            *ngIf="venderForm.get('pincode').hasError('minLength') && (venderForm.get('pincode').touched || submitted)"
                                            class='error-message text-danger'>Pincode must be 6 digit</span>
                                    </div>

                                </div>
                                <div class="button-container">
                                    
                                    <button class="button button4"(click)="cancelBuild('detail')">Cancel</button>
                                    
                                    <button class="button  button5"(click)="venderFormSubmit()">Next</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div *ngIf="vendorStoreType" class="card-header1">
                    <div class="store-details-card-header">
                        <div style="margin-top: -100px;">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="store-details-card-title card-title">
                                        <small class="v1">Store type</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <form [formGroup]="storeForm" #myform="ngForm">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-5 col-md-6">
                                            <div class="col-sm-12">
                                            <label class="small" for="inputLastName"> Store<span style="color: red;">*</span></label>
                                            
                                                <select class="form-control form-control-sm custom-select" formControlName="store" required>
                                                  <option value="Instant_delivery">Instant delivery</option>
                                                  <option value="logistics">Logistics</option>
                                                  <!-- Add more options for other vendors if needed -->
                                                </select>
                                                <span *ngIf="storeForm.get('store').hasError('required') && storeForm.get('store').touched"
                                                  class='error-message text-danger'>Vendor name is required</span>
                                                <span *ngIf="storeForm.get('store').hasError('pattern') && storeForm.get('store').touched"
                                                  class='error-message text-danger'>Special character and double space are not allowed</span>
                                              </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- <div class="card-header-underline1"></div> -->

                                <small class="v2">Select the type of the store</small>
                                <div class="button-container" style="margin-top: 100px;">
                                    <button class="button button4"  (click)="cancelBuild('type')">Cancel</button>
                                    <button class="button button5"(click)="vendorDetailSubmit()" >Next</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  
  <div class="footer ">
    <p style="margin-bottom: 0px; margin-left: 40px;">Powered by : Moonhive</p>
  </div>
