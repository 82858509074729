<!-- Modal -->
<div class="modal overlay" id="usersModal" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">User Information</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3">
                        <p class="card p-2">Rahul</p>
                    </div>
                    <div class="col-md-3 ">
                        <p class="card p-2">KKO CID 908765</p>
                    </div>
                    <div class=" col-md-3">
                        <p class="card p-2">8943086707</p>
                    </div>
                    <div class=" col-md-3">
                        <p class="card">
                            <select class="form-control form-control-sm custom-select" id="exampleFormControlSelect1">
                                <option>Active</option>
                                <option>suspended</option>
                            </select>
                        </p>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="datatable">
                        <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>List Of Orders</th>
                                    <th>Order Content</th>
                                    <th>Order Amount</th>
                                    <th>Delivered Address</th>
                                    <th>Pin Code</th>
                                    <th>Delivery Boy</th>
                                    <th>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>KKO0121456</td>
                                    <td>Chicken Wings</td>
                                    <td><i class="fa fa-inr"></i>200</td>
                                    <td>Flat No.123A wing B safari building pattom trivandrum</td>
                                    <td>699787</td>
                                    <td>Savan</td>
                                    <td class="text-success">Order Accepted</td>

                                </tr>
                                <tr>
                                    <td>KKO0121456</td>
                                    <td>Chicken Wings</td>
                                    <td><i class="fa fa-inr"></i>200</td>
                                    <td>Flat No.123A wing B safari building pattom trivandrum</td>
                                    <td>699787</td>
                                    <td>Savan</td>
                                    <td class="text-warning">Out Of Delivery</td>

                                </tr>
                                <tr>
                                    <td>KKO0121456</td>
                                    <td>Chicken Wings</td>
                                    <td><i class="fa fa-inr"></i>200</td>
                                    <td>Flat No.123A wing B safari building pattom trivandrum</td>
                                    <td>699787</td>
                                    <td>Savan</td>
                                    <td class="text-danger">Open Orders</td>

                                </tr>
                                <tr>
                                    <td>KKO0121456</td>
                                    <td>Chicken Wings</td>
                                    <td><i class="fa fa-inr"></i>200</td>
                                    <td>Flat No.123A wing B safari building pattom trivandrum</td>
                                    <td>699787</td>
                                    <td>Savan</td>
                                    <td class="text-success">Delivered</td>

                                </tr>
                                <tr>
                                    <td>KKO0121456</td>
                                    <td>Chicken Wings</td>
                                    <td><i class="fa fa-inr"></i>200</td>
                                    <td>Flat No.123A wing B safari building pattom trivandrum</td>
                                    <td>699787</td>
                                    <td>Savan</td>
                                    <td class="text-danger">Cancelled</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer"><button class="btn btn-sm   btn -secondary" type="button"
                        data-dismiss="modal">Close</button></div>
            </div>
        </div>
    </div>
</div>