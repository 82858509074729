import { Component, OnInit, Output, ViewChild, EventEmitter, Input, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../modules/admin/services/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateNewStoreService } from 'src/app/modules/admin/services/create-new-store.service';
import { StoreDatasService } from 'src/app/store-datas.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StoreDetailsService } from 'src/app/modules/admin/services/store-details.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() currentPageName = '';
  public accountId: any = localStorage.getItem('userId');
  public StoreID: any = localStorage.getItem('storeunqId');
  public StoreName: any
  logoutPrombt = 'none';
  storeChange = 'none';
  public show: boolean = false;
  public config: any;
  public toastConfig: any;
  public isApiReturned: boolean;
  public orderStatus: any;
  public order_unique_id: any;
  public deliveryBoy: any;
  public createStoreForm: FormGroup;
  public alertStoreData: any;
  public storeList: any = [];
  public storeListItems: any;
  public storedetails: any = []
  public storeSelected: boolean = true;
  public disableButton: boolean;
  public alterNumberCheck: boolean= false;
  public iframeUrl:SafeResourceUrl
  sanitizedUrl : SafeResourceUrl
  public order_id: any;
  public deliveryBoyForm: FormGroup;
  public previewWindow: boolean;
  @ViewChild('content') content: any;
  display = 'none';
  orderDetailShow = 'none';
  addBoyModal = 'none';
  @Output() nameEmitter = new EventEmitter<any>();
  @ViewChild('thumbnailCreate') thumbnailCreate: ElementRef<HTMLCanvasElement>;
  recentStoreID: any;
  expiryDays: any;
  attendationAlert: boolean;
  showExpiredMsg: boolean;
  splitData: any;
  selectedStoreName: any;
  dropMenuState: boolean;
  public webSiteURL: string
  selectedPlan: any;
  currentLogo: any;
  userType: string;
  isSidenavOpen: boolean = true;
  selectedStore: any;
  logoPlaceholder: any;
   @HostListener('document:click', ['$event']) onDocumentClick(event) {

    this.dropMenuState = false;

  }

  isHandset$: Observable<boolean> = this.breakpointObserver

  .observe(['(max-width: 991px)'])

  .pipe(

    map((result) => result.matches),

    shareReplay()

  );
  title: any;

  constructor(
    public router: Router,
    public headerService: HeaderService,
    private formBuilder: FormBuilder,
    private createNewStore: CreateNewStoreService,
    private storeDetails: StoreDatasService,
    private sanitizer: DomSanitizer,
    private storeDatas: StoreDatasService,
    private storeDetailsService: StoreDetailsService,
    private breakpointObserver:BreakpointObserver
    ) {
      this.storeDatas.storePreviewScreen$.subscribe((data)=> {
        if (data == true) {
         this.onClick()
        }
      })
      if(localStorage.getItem('storeunqId') == '' || localStorage.getItem('storeunqId') == 'none') {
        this.headerService.getStoreList(this.accountId).pipe(map(response => response)).subscribe((res: any) => {
            this.recentStoreID = res.response.result.recentstore;
            localStorage.setItem('storeunqId', res.response.result.recentstore)
            this.getStoreId(this.recentStoreID, this.selectedStoreName)
        })
      }
      this.storeDatas.storeDetails$.subscribe((data) => {
        this.StoreID = data;
        this.getDomain()
        this.getLogo()
        // this.iframeUrl = this.sanitizeUrl("https://moonhive-server.in.net/kko/develop/v3/#/guest/"+this.StoreID)
      });
      this.getStoreDetails()
      this.storeDetails.sideNav$.next(true)
    this.createStoreForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern("^(?!.* {2})[a-zA-Z][a-zA-Z ]+[a-zA-Z]")]], //Validators.pattern("^[a-zA-Z]+$")
      type: ['', Validators.required],
      location: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z]+([.-]?[a-z0-9]+)*@([a-z]+([.-]?[a-z]))[.]{1}[a-z]{2,}$")]],
      phone1: ['', [Validators.required, Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")]],
      phone2: ['', [Validators.required, Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")]],
      address: ['', Validators.required]
    })
  }
  onClick() {
    this.iframeUrl= '';
    // this.iframeUrl = this.sanitizeUrl("https://dailystore.io/app-preview/v3/#/store/"+this.StoreID)
    this.iframeUrl = localStorage.getItem('domainUrl')
  }
  ngOnInit() {
    this.getLogo()
    this.currentStoreDetails()
    this.userType = localStorage.getItem('userCategory')
    
    this.showAlert(localStorage.getItem('storeunqId'));
    // this.storeDatas.storeDetails$.subscribe((data) => {
    //   this.StoreID = data;
    //   this.showAlert();
    // })
    this.storeDatas.logoChange.subscribe((data)=> {
      if( data == true ) {
        this.getLogo()
      }
    })
    this.storeDetails.sideNav$.next(true)
    this.getStoreDetails()
    this.headerService.getStoreName(this.accountId).subscribe((res: any) => {
      this.StoreName = res.response.result?.name;
      if(this.StoreName == '' || this.StoreName == undefined) {
        this.StoreName = localStorage.getItem('userName')
      } else {
        this.StoreName = localStorage.getItem('userName')
        // localStorage.setItem('userName', res.response.result?.name)
      }
      
    });

    // this.iframeUrl = this.sanitizeUrl("https://moonhive-server.in.net/kko/app/?storeId="+this.StoreID)
    // https://moonhive-server.in.net/kko/develop/#/guest/DSS1653895712
    // this.iframeUrl = this.sanitizeUrl("https://moonhive-server.in.net/kko/develop/v3/#/guest/"+this.StoreID)
    if(this.currentPageName === 'Settings' || this.currentPageName === 'Store') {
      this.previewWindow= true;
    }else {
      this.previewWindow= false;
    }
    this.getDomain();
    this.storeDatas.logout.subscribe((data)=> {
      if( data == true ) {
        this.logout()
      }
    })
  }

  goToSubscription() {
    localStorage.setItem('upgradePopup', 'open');
    this.router.navigate(['/admin/settings-general']);
  }

  getLogo() {
    this.headerService.storeLogo(localStorage.getItem('storeunqId')).subscribe((res: any)=> {
      this.currentLogo= res?.logo;
      if(this.currentLogo == '' || this.currentLogo == undefined || this.currentLogo == null) {
        this.currentLogo = 'assets/images/dailystoreLogo.png'
      }
    })
  }

  getDomain() {
    this.headerService.domainLink(localStorage.getItem('storeunqId'), localStorage.getItem('trial')).subscribe((res: any)=> {
      localStorage.setItem('domainUrl', res.storeLink)
      this.webSiteURL= localStorage.getItem('domainUrl')
    })
  }

  showDropDown() {
    this.dropMenuState= true;
  }

  openBrowser() {
    window.open(this.webSiteURL,'targetWindow',
                                   `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=414px,
                                    height=896px`);
 return false;
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  currentStoreDetails() {
    this.storeDetailsService.storeDetails(this.StoreID).subscribe((res: any) => {
      this.selectedStoreName= res.response.result.storeview.name
    })
  }

  getStoreDetails() {
    this.headerService.getStoreList(this.accountId).pipe(
      map(response => response)
    ).subscribe((res: any) => {
      if (res?.response?.result?.count == "Store details not found") {
        this.storedetails = [];
      } else {
        this.storedetails = res?.response?.result?.list;
        this.storedetails.forEach(element => {
          if(element.unqId === res.response.result.recentstore) {
            this.selectedStore = element;
            let placeholder = '';
            this.selectedStore.name.split(' ', 2).forEach(el => {
              placeholder +=el.substring(0,1);
            });
            this.logoPlaceholder = placeholder;
          }else if(!res.response.result.recentstore){
            this.selectedStore=res?.response?.result?.list[0]
            let placeholder = '';
            this.selectedStore.name.split(' ', 2).forEach(el => {
              placeholder +=el.substring(0,1);
            });
            this.logoPlaceholder = placeholder;
          }
        });
        if(localStorage.getItem('storeunqId') == '' || localStorage.getItem('storeunqId') == 'none') {
          this.StoreID = res.response.result.recentstore;
          localStorage.setItem('storeunqId', res.response.result.recentstore);
        }
      }
    });
  }


  getStoreId(data, name) {
    if(localStorage.getItem('userRole') == '' || localStorage.getItem('userRole') == undefined || localStorage.getItem('userRole') == null) {
    this.splitData= data
    this.selectedStoreName= name
    this.storeSelected = true;
    localStorage.setItem('storeunqId', this.splitData);
    this.storeDetails.setStoreDetails(this.splitData);
    this.StoreID = localStorage.getItem('storeunqId');
    this.showAlert(localStorage.getItem('storeunqId'));
    this.iframeUrl = localStorage.getItem('domainUrl')
    this.headerService.getPreviousStore(this.accountId, this.StoreID).subscribe((res: any)=> {
    })
    } else {
      this.storeChange= 'block'
    }
    
  }

  closeModalWarning() {
    this.storeChange = 'none';
  }

  onShow() {
    this.show = true
  }

  logout() {
    this.logoutPrombt = 'block';
  }
  logoutStore() {
    localStorage.setItem('userRole', '');
    localStorage.setItem('userCategory', '');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('otpPage');
    localStorage.removeItem('storeunqId');
    localStorage.removeItem('LoginPhoneNumber');
    localStorage.removeItem('storeName');
    localStorage.removeItem('parentId');
    this.storeDatas.logout.next(false);
    this.router.navigate(['guest']).then(()=> window.location.reload());
  }
  closeModalDialog() {
    this.logoutPrombt = 'none';
    this.storeDatas.logout.next(false);
  }

  onChange(event) {
    this.router.navigate([event])
  }

  closeModalDialogForm() {
    this.display = 'none';
  }
  openAddDBoyModal() {
    this.storeDetails.sideNav$.next(true)
    this.router.navigate(['/admin/create_store'])
  }
  deliveryBoyFormSubmit() { }
  closeAddDboyModal() {
    this.addBoyModal = 'none';
  }

  numberCheck() {
    if(this.createStoreForm.controls['phone2'].value == this.createStoreForm.controls['phone1'].value){
      this.alterNumberCheck = true;
      this.disableButton = false
    }else {
      this.alterNumberCheck= false;
      this.disableButton = true
    }

}
 public sanitizeUrl(url) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(`${url}`);

  }
createStore() {
  this.storeDetails.sideNav$.next(false)
  this.router.navigate(['/guest/create_store'])
}

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyNumberKeys(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "400px";
    this.iframeUrl= ''
    this.iframeUrl = this.sanitizeUrl(localStorage.getItem('domainUrl'))
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

public showAlert(data: any) {
  this.headerService.expireyMsg(data).subscribe((res: any)=> {
    this.selectedPlan= res.response.result.plan;
    this.storeDetails.setStore('selectedPlan', res.response.result.plan);
    if(res.response.result.expiry.split("")[0] == '+') {
      this.expiryDays= res.response.result.expiry.split("+")[1]
      this.storeDetails.showWarning$.next(false)
      this.storeDetails.showCard.next(true)
      if(parseInt(this.expiryDays) <= 30 ) {
        this.attendationAlert= true
        this.showExpiredMsg= false
        this.storeDetails.showWarning$.next(false)
        this.storeDetails.showCard.next(false)
        if(parseInt(this.expiryDays) == 0) {
          this.expiryDays= "today"
          this.storeDetails.showWarning$.next(false)
          this.storeDetails.showCard.next(false)
        }
      } else {
        this.attendationAlert= false
        this.showExpiredMsg= false
      }
    } else if(res.response.result.expiry.split("")[0] == '-') {
      this.expiryDays= res.response.result.expiry.split("-")[1]
      if(this.expiryDays > 30) {
        this.storeDetails.hideOrderStatus.next(true)
      } else {
        this.storeDetails.hideOrderStatus.next(false)
      }
      this.attendationAlert= false;
      this.showExpiredMsg= true
      this.storeDetails.showWarning$.next(true)
      this.storeDetails.showCard.next(false)
    } else if(res.response.result.expiry == "") {
      this.storeDetails.showCard.next(true)
      this.storeDetails.showWarning$.next(false)
      this.attendationAlert= false
      this.showExpiredMsg= false
    }
  })
}

hideAlert(data) {
  if(data === 'dateAlert') {
    this.attendationAlert= false
    this.storeDetails.showCard.next(true)
  } else {
    this.showExpiredMsg= false;
    this.storeDetails.showCard.next(true)
  }
}

toggleSidenav() {
      this.isSidenavOpen = !this.isSidenavOpen;
      this.storeDatas.setMenuToggle(this.isSidenavOpen);
}

}
