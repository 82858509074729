<nav class="topnav navbar navbar-expand new-shadow navbar-light bg-white" id="sidenavAccordion">
  <a class="navbar-brand">
    <img rel="icon" src="assets/images/logo (1).png" style="height: 33px; width: 127px;"><span class="pt-2"></span>
  </a>
</nav>

<!-- *ngIf="createStore" -->
<div *ngIf="createStore" style="height: 100%; margin-bottom: 100px;">
  <div class="col-lg-7 mx-auto index" style="margin-top: 60px; margin-bottom: 80px">
    <!-- progress header -->
    <div class="d-flex align-items-center justify-content-center progress-header">
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="assets/images/Group 2837.png" height="30px">
        </div>
        <div class="progress-header-font">
          Basic Info
        </div>
      </div>
      <div style="width:34%;height:3px;background-color:#EAEFF2;"></div>
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="assets/images/Ellipse 140.png" height="30px">
        </div>
        <div class="progress-header-font">
          Store Type
        </div>
      </div>
      <div style="width:34%;height:3px;background-color:#EAEFF2;"></div>
      <div style="position: relative;">
        <div class="d-flex flex-column  align-items-center mt-3" style=" position: absolute;
            right: -75px;
            top: -33px;">
          <div>
            <img src="assets/images/Ellipse 140.png" height="30px">
          </div>
          <div class="progress-header-font">
            Choose Subscription
          </div>
        </div>
      </div>

    </div>
    <!-- progress header end -->

    <!-- Basic registration form -->
    <div class="card create-store mt-2">
      <div class="card-header justify-content-center">
        <div class="card-heading">Store Details</div>
        <div class="card-header-underline"></div>
      </div>
      <div class="card-body">
        <app-toast [config]="alertStoreData" *ngIf="status == 200"></app-toast>
        <!-- Registration form-->
        <form [formGroup]="createStoreForm" #myform="ngForm">
          <!-- Form Group (Store name)-->
          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="small" for="inputStoreName">Store Name</label>
                <input class="form-control form-control-sm " id="inputStoreName" type="text" formControlName="name"
                  style="margin-top: -4px;" required />
                <span *ngIf="createStoreForm.get('name').hasError('required') && createStoreForm.get('name').touched"
                  class='error-message text-danger'>Store name is required</span>
                <span *ngIf="createStoreForm.get('name').hasError('pattern') && createStoreForm.get('name').touched"
                  class='error-message text-danger'>Special character and double space are not allowed</span>
              </div>
              <div class="col-sm-6">
                <div style="position: relative;">
                  <div style="position: absolute; top: 38px; left: 7px;"><img src="assets/images/warning.svg"></div>
                  <div><img style="padding-top: 3px; position: absolute; right: 9px; top: 36px;"
                      src="assets/images/upArrow.svg"></div>
                  <label class="small" for="inputLastName">Profile Image</label>
                  <input class="form-control form-control-sm " id="inputLastName" type="file"
                    style="margin-top: -4px; padding: 11px 0px 0px 25px;" (change)="uploadLogo($event)" #uploadStoreLogo
                    required />
                </div>
              </div>
            </div>
          </div>

          <!-- Form Group (Store location)-->
          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="small" for="inputMailId">Email</label>
                <input class="form-control form-control-sm " id="inputMailId" type="email" formControlName="email"
                  style="margin-top: -4px;" required />
                <span *ngIf="createStoreForm.get('email').hasError('required') && createStoreForm.get('email').touched"
                  class='error-message text-danger'>E-mail is required</span>
                <span *ngIf="createStoreForm.get('email').hasError('pattern') && createStoreForm.get('email').touched"
                  class='error-message text-danger'>Enter the valid Email</span>
              </div>
              <div class="col-sm-6">
                <label class="small" for="inputPhoneNumber" style="margin-bottom: 0px;">Phone
                  Number</label>
                <div>
                  <div class="inputicons">
                    <img src="assets/images/IndianFlag.png" 
                    style=" 
                    margin-left: 13px;
                    width: 20px;
                    margin-bottom: -5px;">
                    <input formControlName="phone1" type="text" class="form-control form-control-sm phone"
                      id="inputPhoneNumber" style="margin-top: -23px; padding-left: 34px;" required />
                    <span
                      *ngIf="createStoreForm.get('phone1').hasError('required') && createStoreForm.get('phone1').touched"
                      class='error-message text-danger'>Phone number is required</span>
                    <span
                      *ngIf="createStoreForm.get('phone1').hasError('pattern') && createStoreForm.get('phone1').touched"
                      class='error-message text-danger'>Enter the valid phone number</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="small" for="inputAlterPhoneNumber" style="margin-bottom: 0px;">Alternate Phone
                  Number</label>
                <div>
                  <div class="inputicons">
                    <img src="assets/images/IndianFlag.png" 
                    style=" 
                      margin-left: 13px;
                      width: 20px;
                      margin-bottom: -5px;">
                    <input class="form-control form-control-sm phone" id="inputAlterPhoneNumber" type="text"
                      (change)="numberCheck()" formControlName="phone2"
                      style="margin-top: -23px; padding-left: 34px;" />
                    <span
                      *ngIf="createStoreForm.get('phone2').hasError('required') && createStoreForm.get('phone2').touched"
                      class='error-message text-danger'>Phone number is required</span>
                    <span
                      *ngIf="createStoreForm.get('phone2').hasError('pattern') && createStoreForm.get('phone2').touched"
                      class='error-message text-danger'>Enter the valid phone number</span>
                    <span *ngIf="alterNumberCheck" class='error-message text-danger'>Phone number should
                      be
                      different</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <label class="small" for="inputStoreAddress">Street Address</label>
                <input class="form-control form-control-sm py-2" id="inputStoreAddress" type="text"
                  formControlName="address" style="margin-top: -4px;" required />
                <span
                  *ngIf="createStoreForm.get('address').hasError('required') && createStoreForm.get('address').touched"
                  class='error-message text-danger'>Street address is required</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="small" for="inputPincode">Pincode</label>
                <input class="form-control form-control-sm " maxlength="6" pattern="[0-9]{6}" id="inputPincode"
                  type="text" formControlName="pincode" style="margin-top: -4px;" />
                <span
                  *ngIf="createStoreForm.get('pincode').hasError('required') && createStoreForm.get('pincode').touched"
                  class='error-message text-danger'>Pincode is required</span>
                <span
                  *ngIf="createStoreForm.get('pincode').hasError('pattern') && createStoreForm.get('pincode').touched"
                  class='error-message text-danger'>Enter the valid pincode</span>
              </div>
              <div class="col-sm-6">
                <label class="small" for="inputCity">City</label>
                <input class="form-control form-control-sm " id="inputCity" type="text" formControlName="city"
                  style="margin-top: -4px;" required />
                <span *ngIf="createStoreForm.get('city').hasError('required') && createStoreForm.get('city').touched"
                  class='error-message text-danger'>City is required</span>
                <span *ngIf="createStoreForm.get('city').hasError('pattern') && createStoreForm.get('city').touched"
                  class='error-message text-danger'>Enter the valid city name</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="small" for="inputCountry">Country</label>
                <input class="form-control form-control-sm " id="inputCountry" type="text" formControlName="country"
                  style="margin-top: -4px;" (keyup)="changeSearch($event)" required />
                <div *ngIf="countrySelected" style="max-height: 200px; overflow-y:auto; margin-top: 5px;">
                  <div *ngFor="let data of countries; let i= index" (click)="selectCountry(data)"
                    style="cursor: pointer;">{{data}}</div>
                </div>
                <span
                  *ngIf="createStoreForm.get('country').hasError('required') && createStoreForm.get('country').touched"
                  class='error-message text-danger'>Country name is required</span>
                <span
                  *ngIf="createStoreForm.get('country').hasError('pattern') && (createStoreForm.get('country').touched || showCountryWarning)"
                  class='error-message text-danger'>Enter the valid country name</span>
                <span *ngIf="showCountryWarning" class='error-message text-danger'>Enter the valid country name</span>
              </div>
              <div class="col-sm-6">
                <label for="inputLocation" class="small" style="margin-bottom: 0px; margin-top: 4px;">Location</label>
                <div>
                  <div class="inputicons" style="position: relative; margin-top: 29px">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      style="padding-top: 3px; position: absolute; right: 9px; top: 12px;" width="16" height="16"
                      fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                      <path
                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <input formControlName="location" type="text" class="form-control form-control-sm location"
                      id="inputLocation" style="margin-top: -27px;" required />
                    <span
                      *ngIf="createStoreForm.get('location').hasError('required') && createStoreForm.get('location').touched"
                      class='error-message text-danger'>location is required</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Group (create account submit)-->
          <div class="form-group mb-0" style="text-align: right; margin-bottom: -8px !important;"
            style="float: right; margin-right: 10px;">
            <div class="row">
              <button *ngIf="this.cancelCheck == 1" type="button" class="btn btn-outline-secondary border mr-3"
                (click)="cancelBuild()" style="color: #2F2F2F;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16.233px;
              letter-spacing: 0.081px;border-radius: 8px;
border: 0.812px solid #BDBDC6;
background: #FFF;width: 80px;
height: 40px">Cancel</button>
              <div *ngIf="!editStore">
                <button [disabled]="!createStoreForm.valid" class="btn" style="margin-right: -1px; border-radius: 8px;
                  background: linear-gradient(90deg, #54CF29 0%, #5BA40C 100%, #5BA40C 100%), #787878;color: #FFF;width: 80px; white-space:nowrap;
height: 40px;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16.233px; 
letter-spacing: 0.081px;" (click)="createStoreFormSubmit()" [disabled]="!createStoreForm.valid">Continue</button>

              </div>
              <div *ngIf="editStore">
                <button [disabled]="!createStoreForm.valid" class="btn" style="margin-right: -1px; border-radius: 8px;
                  background: linear-gradient(90deg, #54CF29 0%, #5BA40C 100%, #5BA40C 100%), #787878;color: #FFF;width: 80px;
height: 40px;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16.233px; 
letter-spacing: 0.081px;" (click)="editFormSubmit()">Update</button>

              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <!-- Basic registration form end-->

  </div>
</div>


<div *ngIf="storeType" style="margin-bottom: 167px;">
  <div class="col-lg-7 mx-auto index" style="margin-top: 112px; margin-bottom: 80px">

    <!-- progress header -->
    <div class="d-flex align-items-center justify-content-center progress-header">
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="assets/images/Group 2836.png"  height="30px">
        </div>
        <div class="progress-header-font">
          Basic Info
        </div>
      </div>
      <div style="width:34%;height:3px;background-color:#73A942;"></div>
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="assets/images/Group 2837.png"  height="30px">
        </div>
        <div class="progress-header-font">
          Store Type
        </div>
      </div>
      <div style="width:34%;height:3px;background-color:#EAEFF2;"></div>
      <div style="position: relative;">
        <div class="d-flex flex-column  align-items-center mt-3" style=" position: absolute;
              right: -75px;
              top: -33px;">
          <div>
            <img src="assets/images/Ellipse 140.png"  height="30px">
          </div>
          <div class="progress-header-font">
            Choose Subscription
          </div>
        </div>
      </div>

    </div>
    <!-- progress header end -->

    <!-- Basic registration form-->
    <div class="card store-type-card border-0 rounded-lg mt-2 mb-2">
      <div class="card-header justify-content-center">
        <div class="title">Store Type</div>
        <div class="card-header-underline"></div>
      </div>
      <div class="card-body">
        <app-toast [config]="alertStoreData" *ngIf="status == 200"></app-toast>
        <!-- Registration form-->
        <form [formGroup]="storeTypeForm">
          <!-- Form Group (Store name)-->
          <div>
            <label class="small" for="inputStoreType" style="margin-bottom: 14px;">Store</label>
            <select (change)="onStoreTypeChange($event)" placeholder="select store type" formControlName="type_category"
              class="form-control form-control-sm custom-select" id="inputStoreType" type="text"
              aria-label="Default select example" style="margin-top: -7px; border-radius: 8px;
              border: 1px solid #929292; height: 49px;
              background: #FFF; font-family: Poppins;">
              <option *ngFor="let data of storeTypeData" [value]="data.value" selected>{{data.label}}</option>
            </select>
            <small style="font-size: 10.5px; color: #8A8A8A;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;" class="text-muted">
              <svg style="margin-right: 5px; " xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
              </svg>Select the type of your store</small>
            <span
              *ngIf="storeTypeForm.get('type_category').hasError('required') && storeTypeForm.get('type_category').touched"
              class='error-message text-danger'>Store type is required</span>
          </div>

          <div *ngIf="storeTypeTitle == 'Instant Delivery'" class="form-group" style="margin-bottom: 50px;">

            <button (click)="getButtonValue('Restaurant', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Restaurant</button>
            <button (click)="getButtonValue('Food and Beverages', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Food and Beverages</button>
            <button (click)="getButtonValue('Super Market', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Super
              Market</button>
            <button (click)="getButtonValue('Margin Free Market', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Margin Free Market</button>
            <button (click)="getButtonValue('Food', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Food</button>
            <button (click)="getButtonValue('Others', 'Instant Delivery')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Other</button>
          </div>

          <div *ngIf="storeTypeTitle == 'Logistics'" class="form-group" style="margin-bottom: 50px;">
            <button (click)="getButtonValue('Electronics', 'Logistics')"
              class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Electronics</button>
            <button (click)="getButtonValue('Mobile', 'Logistics')" class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Mobile</button>
            <button (click)="getButtonValue('Computer', 'Logistics')" class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Computer</button>
            <button (click)="getButtonValue('Textiles', 'Logistics')" class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Textiles</button>
            <button (click)="getButtonValue('Books', 'Logistics')" class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Books</button>
            <button (click)="getButtonValue('Other', 'Logistics')" class="btn-type btn btn-outline-store mr-3 mt-4"
              style="border-radius: 50px;font-family: Poppins; border: 1px solid #5BA40C">Other</button>
          </div>

          <div class="form-group mb-0 button-div" style="text-align: right;">
            <div class="row">
              <button type="button" class="btn btn-outline-secondary border mr-3" style="margin-top: 36px; color: #2F2F2F;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16.233px; /* 135.274% */
              letter-spacing: 0.081px;border-radius: 8px;
border: 0.812px solid #BDBDC6;
background: #FFF;width: 80px;
height: 40px" (click)="back('createStore')">Back</button>
              <div *ngIf="!editPlan">
                <button [disabled]="!storeTypeForm.valid" class="btn btn-store btn-primary" style="margin-top: 36px; margin-right: -3px; border-radius: 8px;
                  background: linear-gradient(90deg, #54CF29 0%, #5BA40C 100%, #5BA40C 100%), #787878;color: #FFF;width: 80px; white-space:nowrap;
height: 40px;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16.233px; 
letter-spacing: 0.081px;" (click)="selectStoreType()">Continue</button>

              </div>
              <div *ngIf="editPlan">
                <button [disabled]="!storeTypeForm.valid" class="btn btn-store btn-primary" style="margin-top: 36px; margin-right: -3px; border-radius: 8px;
                  background: linear-gradient(90deg, #54CF29 0%, #5BA40C 100%, #5BA40C 100%), #787878;color: #FFF;width: 80px; white-space:nowrap;
height: 40px;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16.233px;
letter-spacing: 0.081px;" (click)="editTypeSubmit()">Update</button>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div *ngIf="plan">

  <div>
    <div>
      <div class="container">
        <div style="margin-top: 70px;">
          <!-- progress header -->
          <div class="d-flex align-items-center justify-content-center progress-header" style=" margin-left: -37px;">
            <div class="d-flex flex-column align-items-center mt-3">
              <div>
                <img src="assets/images/Group 2836.png" height="30px">
              </div>
              <div class="progress-header-font">
                Basic Info
              </div>
            </div>
            <div style="width:34%;height:3px;background-color:#73A942;"></div>
            <div class="d-flex flex-column align-items-center mt-3">
              <div>
                <img src="assets/images/Group 2836.png"  height="30px">
              </div>
              <div class="progress-header-font">
                Store Type
              </div>
            </div>
            <div style="width:34%;height:3px;background-color:#73A942;"></div>
            <div style="position: relative;">
              <div class="d-flex flex-column  align-items-center mt-3" style=" position: absolute;
                      right: -75px;
                      top: -33px;">
                <div>
                  <img src="assets/images/Group 2837.png"  height="30px">
                </div>
                <div class="progress-header-font">
                  Choose Subscription
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="!isShow" class="mx-auto index" style="margin-top: 31px;">
        <div *ngIf="!isShow" class="mx-auto index" style="margin-top: 31px;">
          <div id="layoutAuthentication_content">
            <div>
              <div class="custom-tabset">

                <div class="tab-2" [ngClass]="currentTab === 'CURRENT' ? 'selected' : ''"
                  (click)="currentTab = 'CURRENT'">Halfyearly
                </div>
                <div class="tab-m"
                  [ngStyle]="currentTab === 'BUNDLE' ? {'border-radius': '0px 7px 7px 0px'} : {'border-radius': '0px 7px 7px 0px'}"
                  [ngClass]="currentTab === 'BUNDLE' ? 'selected' : ''" (click)="currentTab = 'BUNDLE'">Yearly
                </div>

              </div>


              <div *ngIf="currentTab === 'CURRENT'">

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">

                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #4ce84e; margin-right: 0px;">FREE</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name"
                                style="color: #000000; font-size: 22px; white-space: nowrap;">₹0/Free Forever</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="freePlan('Halfyearly')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #80cdee; cursor: default; margin-bottom: 20px;" class="module">Free
                                  Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 20</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">

                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #A132F9; margin-right: 0px;">BASIC</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name"
                                style="color: #000000; font-size: 22px; white-space: nowrap;">₹5999/6 Months</span>
                            </div>
                            <ng-container>
                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="basicPlan('Halfyearly')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('basic')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 200</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">

                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #f93257; margin-right: 0px;">START UP</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name"
                                style="color: #000000; font-size: 22px; white-space: nowrap;">₹11,999/6 Months</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="deluxePlan('Halfyearly')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('startup')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>

                          </div>
                        </div>

                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">
                            <!-- <div class="bestSelling">
                                <img class="star" src="assets/icons/subscription/white-star.svg" alt="">
                            </div> -->
                            <div class="item" style="text-align: center;">
                              <!-- <img src="assets/planImages/Frame 29.png" style="width: 20%; border-radius: 0px !important;"> -->
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #07DC82; margin-right: 0px;">ENTERPRISE</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name"
                                style="color: #000000; font-size: 22px; white-space: nowrap;">₹17,999/6 Months</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="primePlan('Halfyearly');">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('enterprise')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>

                      </ng-container>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="currentTab === 'BUNDLE'">
                <div class="col-12">
                  <div class="row">

                    <!-- <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let data of yearlyData">
                <ng-container>
                  <div style="margin-bottom: 20px;" class="col">
                      <div class="plan-detail-card">
                          <div class="item" style="text-align: center;">
                        </div>
                          <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #A132F9; margin-right: 0px;">{{data.planName | uppercase}}</span>
                          </div>
                          <div class="item" style="text-align: center;">
                            <span class="plan-name" style="color: #000000; font-size: 22px;">₹{{data.planAmount}}/Free Forever</span>
                        </div>
                          <ng-container>

                            <div
                            class="item d-flex align-items-center justify-content-center w-100">
                            <button style="font-size: 14px; background-color: #5568FE;"
                                class="custom-btn-primary w-100" (click)="freePlan('free')">Subscribe</button>
                        </div>
                        <div class="item d-flex align-items-center justify-content-center">
                          <span style="color: #80cdee; cursor: default; margin-bottom: 20px;"
                              class="module">Free Trial</span>
                      </div>
                      <ng-container>
                        <div class="item">
                          <div class="row">
                              <div>
                                  <span class="access-icon"><img
                                          src="assets/planImages/Frame 30.png" alt=""></span>
                                  <span class="module">{{data.key}}</span>
                              </div>
                          </div>
                      </div>
                        <div class="item">
                          <div class="row">
                              <div>
                                  <span class="access-icon"><img
                                          src="assets/planImages/Frame 30.png" alt=""></span>
                                  <span class="module">Orders : Unlimited</span>
                              </div>
                          </div>
                      </div>
                          <div class="item">
                            <div class="row">
                                <div>
                                    <span class="access-icon"><img
                                            src="assets/planImages/Frame 30.png" alt=""></span>
                                    <span class="module">Categories: Unlimited</span>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                          <div class="row">
                              <div>
                                  <span class="access-icon"><img
                                          src="assets/planImages/Frame 30.png" alt=""></span>
                                  <span class="module">Online Payment</span>
                              </div>
                          </div>
                      </div>
                      <div class="item">
                        <div class="row">
                            <div>
                                <span class="access-icon"><img
                                        src="assets/planImages/Frame 30.png" alt=""></span>
                                <span class="module">Order Tracking</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                      <div class="row">
                          <div>
                              <span class="access-icon"><img
                                      src="assets/planImages/Frame 30.png" alt=""></span>
                              <span class="module">COD</span>
                          </div>
                      </div>
                  </div>
                  <div class="item">
                    <div class="row">
                        <div>
                            <span class="access-icon"><img
                                    src="assets/planImages/Frame 31.png" alt=""></span>
                            <span class="module">Inventory</span>
                        </div>
                    </div>
                </div>
                <div class="item">
                  <div class="row">
                      <div>
                          <span class="access-icon"><img
                                  src="assets/planImages/Frame 30.png" alt=""></span>
                          <span class="module">Logistics</span>
                      </div>
                  </div>
              </div>
              <div class="item">
                <div class="row">
                    <div>
                        <span class="access-icon"><img
                                src="assets/planImages/Frame 30.png" alt=""></span>
                        <span class="module">Catalogue Creation</span>
                    </div>
                </div>
            </div>
            <div class="item">
              <div class="row">
                  <div>
                      <span class="access-icon"><img
                              src="assets/planImages/Frame 30.png" alt=""></span>
                      <span class="module">Offer Banners</span>
                  </div>
              </div>
          </div>
          <div class="item">
            <div class="row">
                <div>
                    <span class="access-icon"><img
                            src="assets/planImages/Frame 31.png" alt=""></span>
                    <span class="module">Coupon Code Generator</span>
                </div>
            </div>
        </div>
        <div class="item">
          <div class="row">
              <div>
                  <span class="access-icon"><img
                          src="assets/planImages/Frame 31.png" alt=""></span>
                  <span class="module">Graphic Editing Tools</span>
              </div>
          </div>
      </div>
      <div class="item">
        <div class="row">
            <div>
                <span class="access-icon"><img
                        src="assets/planImages/Frame 30.png" alt=""></span>
                <span class="module">Share Products to Social Media</span>
            </div>
        </div>
    </div>
    <div class="item">
      <div class="row">
          <div>
              <span class="access-icon"><img
                      src="assets/planImages/Frame 30.png" alt=""></span>
              <span class="module">Website - Android & iOS (Mobile only)</span>
          </div>
      </div>
  </div>
  <div class="item">
    <div class="row">
        <div>
            <span class="access-icon"><img
                    src="assets/planImages/Frame 31.png" alt=""></span>
            <span class="module">Store APK Generation</span>
        </div>
    </div>
</div>
<div class="item">
  <div class="row">
      <div>
          <span class="access-icon"><img
                  src="assets/planImages/Frame 31.png" alt=""></span>
          <span class="module">Store AAB - Playstore Listing</span>
      </div>
  </div>
</div>
<div class="item">
<div class="row">
    <div>
        <span class="access-icon"><img
                src="assets/planImages/Frame 31.png" alt=""></span>
        <span class="module">Staff Accounts</span>
    </div>
</div>
</div>
<div class="item">
<div class="row">
  <div>
      <span class="access-icon"><img
              src="assets/planImages/Frame 31.png" alt=""></span>
      <span class="module">Bussiness Analytics</span>
  </div>
</div>
</div>
<div class="item">
<div class="row">
  <div>
      <span class="access-icon"><img
              src="assets/planImages/Frame 31.png" alt=""></span>
      <span class="module">Toll Free</span>
  </div>
</div>
</div>
<div class="item">
<div class="row">
  <div>
      <span class="access-icon"><img
              src="assets/planImages/Frame 31.png" alt=""></span>
      <span class="module">Chat</span>
  </div>
</div>
</div>
<div class="item">
<div class="row">
  <div>
      <span class="access-icon"><img
              src="assets/planImages/Frame 31.png" alt=""></span>
      <span class="module">Email</span>
  </div>
</div>
</div>
                      </ng-container>
                          </ng-container>
                      </div>
                  </div>
              </ng-container>
              </div> -->




                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">
                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #4ce84e; margin-right: 0px;">FREE</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #000000; font-size: 22px;">₹0/Free Forever</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="freePlan('free')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #80cdee; cursor: default; margin-bottom: 20px;" class="module">Free
                                  Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 20</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">
                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #A132F9; margin-right: 0px;">BASIC</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #000000; font-size: 22px;">₹9,999/ Year</span>
                            </div>
                            <ng-container>
                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="basicPlan('Year')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('basic')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 200</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">
                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #f93257; margin-right: 0px;">START UP</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #000000; font-size: 22px;">₹19,999/ Year</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="deluxePlan('Year')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('startup')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 200</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 31.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>

                      </ng-container>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <ng-container>
                        <div style="margin-bottom: 20px;">
                          <div class="plan-detail-card">
                            <div class="item" style="text-align: center;">
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #07DC82; margin-right: 0px;">ENTERPRISE</span>
                            </div>
                            <div class="item" style="text-align: center;">
                              <span class="plan-name" style="color: #000000; font-size: 22px;">₹29,999/ Year</span>
                            </div>
                            <ng-container>

                              <div class="item d-flex align-items-center justify-content-center w-100">
                                <button style="font-size: 14px; background-color: #5568FE;"
                                  class="custom-btn-primary w-100" (click)="primePlan('Year')">Subscribe</button>
                              </div>
                              <div class="item d-flex align-items-center justify-content-center">
                                <span style="color: #077DB1; cursor: pointer; margin-bottom: 20px;" class="module"
                                  (click)="freeTrial('enterprise')">Free Trial</span>
                              </div>
                              <ng-container>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Products - Upto 200</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Orders : Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Categories: Unlimited</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Online Payment</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Order Tracking</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">COD</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Inventory</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Logistics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Catalogue Creation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Offer Banners</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Coupon Code Generator</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Graphic Editing Tools</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Share Products to Social Media</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Website - Android & iOS (Mobile only)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store APK Generation</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Store AAB - Playstore Listing</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Staff Accounts</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Bussiness Analytics</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Toll Free</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Chat</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="item">
                                  <div class="row">
                                    <div>
                                      <span class="access-icon"><img src="assets/planImages/Frame 30.png" alt=""></span>
                                      <span class="module">Email</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>

                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isShow" style="margin-bottom: 135px; margin-top: 90px;">
        <table class="table table-bordered">
          <thead style="display: table;
              width: 100%;
              table-layout: fixed;">
            <tr>
              <td style="border-top: none; text-align: center; padding-top: 100px;">
                <div style="font-weight: 400;
                    font-size: 24px; color: #3A3A3A;">Our Plans</div>

                <button class="btn btn-outline-primary"
                  style="margin-top: 10px; border: 1px solid#ced4da; width: 114px; height: 30px; color: #3A3A3A;"
                  (click)="onClickOurPlan()">Back</button>
              </td>

              <td style="border-top: none; text-align: center; padding: 57px 40px 57px 40px;">
                <div><span class="plan">0%</span><span class="planSub">/Order + Gst</span></div>
                <div class="basic">Basic</div>
                <button type="button" class="btn btn-lg btn-outline-primary border" (click)="basicPlan()"
                  style="margin-top: 30px; width: 100%; font-size: 18px; font-family: 'Roboto'; color: #3A3A3A;">Free</button>
              </td>
              <td style="border-top: none; text-align: center; padding: 57px 40px 57px 40px;">
                <div><span class="plan">2%</span><span class="planSub">/Order + Gst</span></div>
                <div class="basic">Deluxe</div>
                <button type="button" class="btn btn-lg btn-outline-primary border" (click)="deluxePlan()"
                  style="margin-top: 30px; width: 100%; font-size: 18px; font-family: 'Roboto'; color: #3A3A3A;">Subscribe</button>
                <div style="color:#5568FE; font-size: 14px; font-family: 'Roboto'; margin-top: 20px;">1 month free trial
                </div>
              </td>
              <td style="border-top: none; text-align: center; padding: 57px 40px 57px 40px;">
                <div><span class="plan">2.5%</span><span class="planSub">/Order + Gst</span></div>
                <div class="basic">Prime</div>
                <button type="button" class="btn btn-lg btn-outline-primary border" (click)="primePlan()"
                  style="margin-top: 30px; width: 100%; font-size: 18px; font-family: 'Roboto'; color: #3A3A3A;">Subscribe</button>
                <div style="color:#5568FE; font-size: 14px; font-family: 'Roboto'; margin-top: 20px;">1 month free trial
                </div>
              </td>
              <td style="border-top: none; text-align: center; padding: 57px 40px 57px 40px;">
                <div><span class="plan">3%</span><span class="planSub">/Order + Gst</span></div>
                <div class="basic">Enterprise</div>
                <button type="button" class="btn btn-lg btn-outline-primary border" (click)="enterprisePlan()"
                  style="margin-top: 30px; width: 100%; font-size: 18px; font-family: 'Roboto'; color: #3A3A3A;">Subscribe</button>
                <div style="color:#5568FE; font-size: 14px; font-family: 'Roboto'; margin-top: 20px;">1 month free trial
                </div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-outline-secondary border mr-3"
    style="margin-bottom: 136px; padding: 5px 35px; margin-left: 15px;" (click)="back('storeType')">Back</button>
</div>

<!-- *ngIf="confirm" -->
<div *ngIf="confirm">
  <div class="container" >
    <div class="col-lg-9 mx-auto">
      <!-- store details card -->
      <div class="card confirmation-card" >
        <div class="store-details-card-header" style="padding:18px;">

          <div class="d-flex justify-content-between" style="margin-bottom: -6px;">
            <div>
              <div class="title"><strong>Store Details</strong></div>
            </div>
            <div>
              <button style="margin-right: -13px !important; font-size: 12px;"
                class="btn btn-sm btn-link store-details-card-edit" (click)="edit()">Edit
              </button>
            </div>
          </div>

          <div class="card-header-underline"></div>

          <div class="row">
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Store Name</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('name').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Email</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('email').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Phone Number</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('phone1').value }}</h3>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Alternate Phone Number</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('phone2').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Street Address</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('address').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Pincode</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('pincode').value }}</h3>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">City</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('city').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Country</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('country').value }}</h3>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="store-details-item">
                <small class="small">Location</small>
                <h3 class="store-details-item-datas">{{ createStoreForm.get('location').value }}</h3>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- store details card end -->

      <!-- store type card -->
      <div class="card" style="margin-bottom: 20px;">
        <div class="store-details-card-header" style="padding: 18px;">

          <div class="d-flex justify-content-between">
            <div>
              <div class="title"><strong>Store Type</strong></div>
            </div>
            <div>
            </div>
          </div>
          <div class="card-header-underline"></div>
          <div class="container" style="padding-left: 13px !important; margin-top: 18px;">
            <div class="row">
              <div class="instant-delivery">{{storeTypeTitle}}:- </div>
              <!-- <div class="confirm-type">{{ storeTypeForm.get('type').value }}</div> -->
              <button (click)="back('storeType')" class="confirm-type">{{ storeTypeForm.get('type').value
                }}</button><br>
            </div>
          </div>
        </div>
      </div>
      <!-- store type card end -->

      <!-- store plan card -->
      <div class="card plan-confirm-card" style="margin-bottom: 20px;">
        <div class="store-details-card-header" style="padding: 18px; margin-bottom: 10px;">

          <div class="d-flex justify-content-between">
            <div class="container">
              <div class="row" style="margin-top: 15px;">
                <div class="title" style="margin-top: 4px; margin-left: -10px;"><strong>Current subscription plan :-
                  </strong></div>
                <div class="plan-type">{{selectPlan | titlecase}}</div>
                <div *ngIf="selectPlan == 'basic' && isTrial" class="plan-type">Active Free Trial</div>
                <div *ngIf="selectPlan == 'startup' && isTrial" class="plan-type">Active Free Trial</div>
                <div *ngIf="selectPlan == 'enterprise' && isTrial" class="plan-type">Active Free Trial</div>

              </div>
            </div>
            <div>
              <button (click)="back('plan')" class="btn btn-upgrade">Upgrade</button>
            </div>
          </div>
        </div>
      </div>
      <!-- store plan card end -->

      <!-- continue button -->
      <div>
        <div class="continue-btn-div" style="margin-bottom: 150px;">
          <button type="button" class="btn btn-outline-secondary border mr-3" (click)="cancel()">Cancel</button>
          <button *ngIf="!isTrial" [disabled]="!createStoreForm.valid" class="btn"
            style="margin-right: -1px; background-color: #5568FE; color: white;"
            (click)="createStoreDatas()">Confirm</button>
          <button *ngIf="isTrial" [disabled]="!createStoreForm.valid" class="btn"
            style="margin-right: -1px; background-color: #5568FE; color: white;"
            (click)="paymentSuccess('free')">Confirm</button>
        </div>
      </div>
      <!-- continue button end -->
    </div>
  </div>
</div>
<div class="footer">
  <p style="margin-top: 10px;">Powered by: Moonhive</p>
</div>