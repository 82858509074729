import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/modules/admin/services/login.service';
import { OtpServiceService } from 'src/app/modules/admin/services/otp-service.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOTPComponent implements OnInit {

  public otpForm: FormGroup;
  public counter: number;
  public status: any;
  public result: any;
  public isApiReturned: boolean;
  public toastConfig: any;
  public submitted: any = false
  public timerRuns: any = false

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private otpService: OtpServiceService,
    private loginService: LoginService,) {
    this.counter = 60;
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    })
  }


  ngOnInit() {
    this.startCounter();
  }

  otpFormSubmit() {
    if (!this.otpForm.valid) {
      this.submitted = true
    } else {
      localStorage.setItem('storeunqId', "none");
      var obj: any = {}
      if (localStorage.getItem('otpPage') == "1") {
        obj = {
          "password": "",
          "otp": this.otpForm.value.otp,
          "user": localStorage.getItem('userId'),
          "method": "login",
          "storeId": "dashboard",
          "role": "SO"
        }
      } else {
        obj = {
          "password": "",
          "otp": this.otpForm.value.otp,
          "user": localStorage.getItem('userId'),
          "method": "signup",
          "storeId": "dashboard",
          "role": "SO"
        }
      }
      this.otpService.otpVerify(obj).subscribe((res: any) => {
        this.isApiReturned = true;
        if (res.error || res.response.status !== 200) {
          this.otpForm.reset();
          this.toastConfig = {};
          this.toastConfig.error = true;
          this.toastConfig.result = res.response.result;
        }
        if (res.response.status === 200) {
          localStorage.setItem('token', res.response.result.token);
          if (localStorage.getItem('otpPage') == "1") {
            localStorage.setItem('userCategory', 'Admin')
            localStorage.setItem('userName', res.response.result.my_profile.name)
            this.router.navigate(['/admin'])
            this.otpForm.reset();
          } else if (localStorage.getItem('otpPage') == "2") {
            this.router.navigate(['/guest/create_store'])
            this.otpForm.reset();
          }
        }
      })
    }

  }

  resendOtp() {
    this.resetCounter();
    // if (this.timerRuns == true) {

    // } else {
      let loginPhoneNumber = localStorage.getItem('LoginPhoneNumber')
      let data={
        mobile:loginPhoneNumber
      }
      this.loginService.loginCheck(data).subscribe((res: any) => {
        localStorage.setItem('userId', res.response.result.unqId)
        this.isApiReturned = true;
        if (res.error || res.user.response.status !== 200) {
          this.toastConfig = {};
          this.toastConfig.error = true;
          this.toastConfig.result = res.response.result;
        } else
          if (res.user.response.status === 200) { }
      }, (error) => {
        this.toastConfig = {};
        this.toastConfig.result = 'Error in login please try again';
      })
    // }
  }

  private startCounter() {
    this.timerRuns = true
    let timer;
    timer = setInterval(countDown, 1000);
    const self = this;

    function countDown() {
      if (self.counter === 0) {
        clearTimeout(timer);
        self.timerRuns = false
      } else {
        self.counter--;
      }
    }
  }

  private resetCounter() {
    this.counter = 60;
    this.startCounter();
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }
}
