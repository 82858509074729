import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoading = new Subject<boolean>();

  constructor() { }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  getAuthorizationData(): Observable<any> {
    let tokenData = localStorage.getItem("a");
    // let tokenData = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjQ2MzE5MTksImlzcyI6ImxvY2FsaG9zdCIsInVzZXJJZCI6Ik9HWVUxNjU0NTc4MTM5In0.9fOTtayLyeijChoKMxcGEBPX2ECV9fe1GB1DTSQucuI';
    return of(tokenData == null ? null : tokenData);
  }

  showLoader() {
    this.isLoading.next(true);
  }

  hideLoader() {
    this.isLoading.next(false);
  }
}
