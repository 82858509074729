import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public StoreID: any = localStorage.getItem('storeunqId');

  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseUrlNode = `${environment.apiUrlNode}`;

  constructor(private http: HttpClient) { }

  public domainLink(storeId, trial) {
    return this.http.get(this.apiBaseUrlNode+`storeName/storelink?storeId=${storeId}&trial=${trial}`)
  }

  public storeLogo(storeId) {
    return this.http.get(this.apiBaseUrlNode+`storeId/logo?storeId=${storeId}`)
  }

  public storeLogoUpdate(storeId, logo) {
    return this.http.put(this.apiBaseUrlNode+`storeId/updateLogo`,
    {
      storeId: storeId,
      logo: logo
    })
  }

  public getStoreName(data) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'myProfile',
      param: {
        unqId: data
      }
    })
  }

  public getStoreList(data) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'storeListing',
      param: {
        uid: data
      }
    })
  }

  public getPreviousStore(userId, storeId) {
    return this.http.post(`${this.apiBaseUrl}`,
    {
      name:'recentUpdatestore',
      param: {
        unqId: userId,
        storeId: storeId
      }
    })
  }

  public expireyMsg(data) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'verifyStoreplandetails',
      param: {
        id: data
      }
    })
  }
}
