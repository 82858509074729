<div class="d-flex justify-content-between">



  <div class="col-lg-6 mx-auto loginContent">
    <!-- Basic login form-->
    <h2 style="margin-left: 60px; margin-top: 50px;" class="card-heading">Reach & Retain Customers</h2>
    <div class="description" style="margin-left: 60px; margin-right: 45px;">
      Reach a newer audience, provide a convenient shopping experience, and build long lasting relationships with your customers.
    </div>
    <br>
    <div style="text-align: center; margin-top: 24px;">
      <img src="assets/images/otp-login.png" style="width: 60%;">
    </div>
  </div>




  <div class="col-lg-6 mx-auto coloum-2" style="margin-top: -90px;
  background: #FFF;
  min-height: 100vh;
  -webkit-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
-moz-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
">
    <div style="text-align: center; margin-top: 150px;" class="green_logo"><img src="assets/images/green_logo.png"></div>
    <!-- One Time Password form-->
    <div class="col-2-section">
      <div class="col-2-heading">Verify OTP</div>
      <div class="card-body">
        <app-toast [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>
        <!-- One Time Password form-->
        <form [formGroup]="otpForm" (ngSubmit)="otpFormSubmit()" #myform="ngForm">
          <!-- Form Group (OTP)-->
          <div class="form-group">
            <mat-form-field appearance="outline" class="matDesign">
              <mat-label style="color: black" for="inputOtp">Enter OTP</mat-label>
              <input matInput placeholder="Enter OTP" id="inputOtp" type="text" maxlength="4" formControlName="otp"
                (keypress)="onlyNumberKey($event)" autofocus />
            </mat-form-field>
            <span *ngIf="otpForm.get('otp').hasError('required') && (otpForm.get('otp').touched || submitted)"
              class='error-message text-danger'>Enter the valid OTP</span>
          </div>
          <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-2">
            <button class="btn container verify"  type="submit"
              >Verify</button>
          </div>
        </form>
        <div class="d-flex justify-content-center">
          <p class="otp"><a (click)="resendOtp()" href="javascript:void(0)"  class="regenerate" [class.disabled]="timerRuns">Regenerate</a> OTP in {{counter}}
            seconds</p>
        </div>
      </div>

<br>
      <div style="text-align: center;">
        <div class="signup">Don’t have an account ?<a routerLink="/guest/signup" style="color: #4CAF50" href="">Sign Up</a></div>
      </div>
    </div>
    <br>
    <div class="terms">
      By continuing, you agree to our Terms of Service and Privacy Policy
    </div>
  </div>
</div>
