import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class VendorService {

  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseCommunication = `${environment.apiCommunication}`;
  private apiBaseURLNode = environment.apiUrlNode

  constructor(private http: HttpClient) { }

  public inviteLink(data): Observable<any> {
    return this.http.post(`${this.apiBaseCommunication}`,data);
  }

  public addVendor(data): Observable<any> {
    return this.http.post(this.apiBaseURLNode+`vendor/add-vendor`, data)
  }

  public getVendorList(storeId, page, limit, keys): Observable<any> {
    return this.http.get(this.apiBaseURLNode+`vendor/vendor-list?storeId=${storeId}&page=${page}&limit=${limit}&key=${keys}`)
  }

  public getSelectedVendor(unqId): Observable<any> {
    return this.http.get(this.apiBaseURLNode+`vendor/vendor-list-selected?selectedId=${unqId}`)
  }

  public getSelectedUserIdVendor(unqId): Observable<any> {
    return this.http.get(this.apiBaseURLNode+`vendor/vendor-list-selected-userId?userId=${unqId}`)
  }

  public deleteVendor(unqId): Observable<any> {
    return this.http.get(this.apiBaseURLNode+`vendor/vendor-delete?unqId=${unqId}`)
  }

  public editVendorBankDetails(data) {
    return this.http.put(this.apiBaseURLNode+`vendor/edit-vendor-Detail`, data)
  }

}
