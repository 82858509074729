import { Component, OnInit,Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-deal-table',
  templateUrl: './deal-table.component.html',
  styleUrls: ['./deal-table.component.scss']
})
export class DealTableComponent implements OnInit {
  @Input() config: any;
  @Input() data: any;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  
  @Output() dealEdit = new EventEmitter<string>();
  @Output() catalogDelete = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
   
  }
  
  editPrombt(row){
    this.dealEdit.emit(row);
  }
  deletePrombt(row){
  }
  catalogStatusChange(row){
    this.catalogDelete.emit(row);
  }
}
