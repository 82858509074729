import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderService } from '../../modules/admin/services/header.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  public showloader: boolean = false;
  public showSuccess: boolean = false;
  private subscription: Subscription;
  private timer: Observable<any>;
  public userName: string;
  public accountId: any = localStorage.getItem('userId');
  public isActive: boolean = false;

  constructor(private router: Router, public headerService: HeaderService,) { }

  ngOnInit() {
    if(localStorage.getItem('enableLoader') == 'true'){
      localStorage.setItem('enableLoader', 'false');
      this.setTimer();
    }
    else{
      this.router.navigate(['/admin'])
    }
    this.headerService.getStoreName(this.accountId).subscribe((res: any) => {
      this.userName = res.response.result.name;
    });
    // this.userName = localStorage.getItem('userName');
  }

  public ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  public setTimer() {
    this.showloader = true;
    this.timer = timer(3800); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showloader = false;
      this.storeCreated();
    });
  }

  public storeCreated() {
    this.timer = timer(2000);
    this.showloader = false;
    this.showSuccess = true;
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.router.navigate(['/admin'])
    });
  }

}
