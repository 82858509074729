import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdduserService {

  private apiBaseUrl = `${environment.apiUrl}`;
  private apiBaseUrlNode = `${environment.apiUrlNode}`;
  public StoreID: any = localStorage.getItem('storeunqId');

  constructor(private http: HttpClient) { }

  public addUser(name, email,role, passWord, phone, userId, storeID) {
    return this.http.get(this.apiBaseUrlNode+`add-staff?name=${name}&phone=${phone}&email=${email}&role=${role}&passWord=${passWord}&storeId=${storeID}&userId=${userId}`);
  }

  public getUser(storeId, key, page, limit) {
    return this.http.get(this.apiBaseUrlNode+`add-staff/get-staff?storeId=${storeId}&key=${key}&page=${page}&limit=${limit}`);
  }

  public editUser(unqId, name, email, role, password, phone) {
    return this.http.get(this.apiBaseUrlNode+`add-staff/edit-staff?unqId=${unqId}&name=${name}&email=${email}&role=${role}&phone=${phone}&passWord=${password}&storeId=${this.StoreID}`);
  }

  public deleteUser(unqId) {
    return this.http.get(this.apiBaseUrlNode+`add-staff/delete-staff?unqId=${unqId}`);
  }

  public loginWithUser(email, passWord) {
    return this.http.get(this.apiBaseUrlNode+`add-staff/login-using-staff?email=${email}&passWord=${passWord}`);
  }

  public getRoles(plan) {
    return this.http.get(this.apiBaseUrlNode+`add-staff/get-role?plan=${plan}`);
  }
}
