import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../modules/admin/services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otplogin',
  templateUrl: './otplogin.component.html',
  styleUrls: ['./otplogin.component.scss']
})
export class OtploginComponent implements OnInit {

  public loginForm: FormGroup;
  public alertLoginData: any;
  public status: any;
  public result: any;
  public isApiReturned: boolean;
  public authentication: any;
  public storeId: any;
  public toastConfig: any;
  public submitted: any = false;

  constructor(
    private router: Router,
    private formBuilder:FormBuilder,
    public http: HttpClient,
    private loginService : LoginService,
    private toastr: ToastrService,
  ) {
    this.loginForm = this.formBuilder.group(
      {
        phone: ['',[Validators.required, Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")]]
      }
    )
  }

  ngOnInit(): void {
  }

  loginFormSubmit() {
    if(!this.loginForm.valid) {
      this.submitted = true
    } else {
      localStorage.setItem('LoginPhoneNumber', this.loginForm.value.phone)
      let data = {
        mobile: this.loginForm.value.phone
      }
      this.loginService.loginCheck(data).subscribe(
        (res: any) => {
          localStorage.setItem('userId', res.user.response.result.unqId)
          this.isApiReturned = true;
          if(res.error || res.user.response.status !== 200) {
            this.loginForm.reset();
            this.toastConfig = {};
            this.toastConfig.error = true;
            this.toastConfig.result = res.response.result;
            this.copyTextError('Error in login please try again')
          }
          if (res.user.response.status === 200) {
            localStorage.setItem('otpPage', "1");
            this.loginForm.reset();
            this.router.navigate(['/guest/verify_otp']);
            if((localStorage.getItem('key') === res.response.result.token) && (localStorage.getItem('userId') === res.response.result.storeId.$oid)){
              localStorage.setItem('otpPage',"1");
              this.router.navigate(['/guest/verify_otp']);
            }
          }

        }, (error: HttpErrorResponse) => {
          this.toastConfig = {};
          this.toastConfig.result = 'Error in login please try again';
          this.copyTextError(error.error.message)
        });
    }

  }

  onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0)? null: event.charCode >= 48 && event.charCode <= 57;
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }

  goToLogin() {
    this.router.navigate(['/guest']).then(() => window.location.reload());
  }

  goToSignin() {
    this.router.navigateByUrl('guest/signup').then(() => window.location.reload());
  }

  copyTextError(data: any) {
    this.toastr.error(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

  copyTextSuccess(data: any) {
    this.toastr.success(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

}
