import { Component, OnInit} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { StoreDatasService } from './store-datas.service';
// import { SocketService } from './socket.service';
import { Subject } from 'rxjs';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // msgInput: string = 'lorem ipsum';
  title = 'admin-dashboard';
  showHead: boolean = false;
  storeId = '5f411e7456765415db0e3cb2';
  message:any = null;
  isLoading: Subject<boolean> = this.authService.isLoading;

  constructor(private router: Router,private storeDetails: StoreDatasService, private authService: AuthService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/' || event['url'] == '/guest' || event['url'] == '/guest/signup' || event['url'] == '/guest/forgot_password' || event['url'] == '/guest/verify_otp' || event['url'] == '/guest/create_store' || event['url'] == '/guest/fcea920f7412b5da7be0cf42b8c93759' || event['url'] == 'otp-login') {
          this.showHead = false;
        }
        else {
          this.showHead = true;
        }
      }
    });

  }

  ngOnInit() {
    this.storeDetails.sideNav$.next(true);
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
         } else {
           console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message=payload;
    });
  }


}
